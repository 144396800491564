import {
	debounce,
} from 'lodash';

import Ctrl, {
	onAction,
	onChange,
	onClick,
} from 'ctrl.deco';

import {
	makeUid,
	sanitizeHtml,
} from 'BambooHR.util';

import SetAccessLevelModal from './set-access-level-modal.react';


/**
 *
 * @param {SettingsAccessLevelsNoAccessCtrl} ctrl
 */
function _updateSelectedRows({
	$checkboxes,
	$setAccessLevel,
}) {
	const $checked = $checkboxes.filter(':checked');
	const $rows = $checkboxes.closest('tbody tr');
	const selectedClass = 'fab-Table__row--selected';

	$rows
		.removeClass('DataTable__row--active')
		.each((i, row) => {
			const isChecked = $(row).has('[data-action="selectRow"]:checked').length > 0;

			$(row).toggleClass(selectedClass, isChecked);
		});

	$setAccessLevel.toggleDisabled($checked.length < 1);

}

const initTooltips = debounce(() => {
	BambooHR.Components.Tooltip.init();
}, 10);

function _createCheckbox({
	userId,
	employeeId,
	isDisabled,
	hasEmail,
	firstName,
}) {
	const id = makeUid();

	initTooltips();

	return {
		html: `
			<div class="fab-Checkbox">
				<input
					type="checkbox"
					class="fab-Checkbox__input"
					data-action="selectRow"
					id="${ id }"
					value="${ userId || employeeId }"
					data-id-type="${ userId ? 'user' : 'employee' }"
					${ isDisabled ? 'disabled="disabled"' : '' }
				/>
				<span class="fab-Checkbox__box"></span>
				<label
					class="fab-Checkbox__label"
					data-bi-id="settings-access-levels-no-access-row-checkbox"
					for="${ id }"
					${ hasEmail ? '' : `data-tip-content="${ $.__('%1 needs a home or work email in order to be added to an Access Level.', sanitizeHtml(firstName)) }"` }
				></label>
			</div>
		`,
	};
}

@Ctrl('/settings/access_levels/no_access')
class SettingsAccessLevelsNoAccessCtrl {

	dataTableConfig = {
		order: [[1, 'asc']],
		rowCallback(row, data) {
			$(row).toggleClass('DataTable__row--disabled', data.isDisabled);
		},
	};

	columns = [
		{
			name: 'checkbox',
			type: 'ba-html',
			orderable: false,
			defaultContent: '',
			data: _createCheckbox,
		},
		{
			name: 'employeeName',
			title: $.__('Name'),
			type: 'employeeName',
		},
		{
			name: 'accessLevel',
			data: 'accessLevels',
			title: $.__('Previous Access Level'),
		},
		{
			name: 'lastLogin',
			title: $.__('Last Login'),
			type: 'date',
		},
	];

	tmplData = {
		canAdd: false,
		canDuplicate: false,
		canDelete: false,
	};

	emptyTmplData = {
		message: $.__('All Employees in your Company currently have an active Access Level'),
	};

	headerTmplData = {
		showAddBtn: false,
		showSettingsBtn: false,
		extraButtons: [
			{
				className: 'btnAction disabled',
				action: 'setAccessLevel',
				label: $.__('Set Access Level'),
				disabled: true,
			},
		],
	};

	bodyTmplData = {
		showCheckboxes: true,
	};

	/** @type {JQuery<HTMLTableRowElement>} */
	get $rows() {
		return $('.DataTable__body tr');
	}

	/** @type {JQuery<HTMLButtonElement>} */
	get $setAccessLevel() {
		return $('button[data-action="setAccessLevel"]');
	}

	/** @type {JQuery<HTMLInputElement>} */
	get $checkboxes() {
		return $('input[data-action="selectRow"]:not(:disabled)');
	}

	/** @type {string[]} */
	get userIds() {
		return this.$checkboxes
			.filter('[data-id-type="user"]:checked')
			.toArray().map(({ value }) => value);
	}

	/**
	 * @type {string[]}
	 */
	get employeeIds() {
		return this.$checkboxes
			.filter('[data-id-type="employee"]:checked')
			.toArray().map(({ value}) => value);
	}

	/**
	 *
	 * @param {JQuery.ClickEvent} e
	 * @param {SettingsAccessLevelsNoAccessCtrl} ctrl
	 */
	@onAction('setAccessLevel')
	_onSetAccessLevelBtnClick(e, { userIds, employeeIds }) {
		window.BambooHR.Modal.setState({
			isOpen: true,
			isLoading: true,
			title: $.__(`Set Access Level`),
			primaryActionText: $.__(`Save`),
			primaryAction: null,
			content: (
				<SetAccessLevelModal
					{ ...{ userIds, employeeIds } }
				/>
			),
		});
	}

	/**
	 *
	 * @param {JQuery.ChangeEvent} e
	 * @param {SettingsAccessLevelsNoAccessCtrl} ctrl
	 */
	@onChange('[data-action="selectRow"]')
	_onSelectRow(e, ctrl) {
		_updateSelectedRows(ctrl);
	}

	/**
	 *
	 * @param {JQuery.ClickEvent} e
	 * @param {SettingsAccessLevelsNoAccessCtrl} ctrl
	 */
	@onClick('tbody tr')
	_onTableRowSelect(e, ctrl) {
		let $checkbox = $(this).find('[data-action="selectRow"]:not(:disabled)');

		$checkbox
			.prop('checked', !$checkbox.prop('checked'))
			.change();
	}

	formatTableRow(row) {
		row.isDisabled = (
			!row.hasEmail
		);

		return row;
	}
}
