import { ReactElement } from 'react';
import { ActiveMissingInfoProvider } from './context/active-missing-info-provider';
import { ActiveMissingInfoContent } from './components/active-missing-info-content';

import { ActiveMissingInfoProps } from './types';
import { LayoutBox, PageHeader, Section } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';

export function ActiveMissingInfo(props: ActiveMissingInfoProps): ReactElement {
	const { isMultiEin, companies } = props;

	return (
		<ActiveMissingInfoProvider companies={companies} isMultiEin={isMultiEin}>
			<>
				<PageHeader divider={ifFeature('encore', false, true)} title={$.__('Clean Up Missing Employee Info')} />

				{ifFeature(
					'encore',
					<LayoutBox marginY={3}>
						<Section>
							<ActiveMissingInfoContent />
						</Section>
					</LayoutBox>,
					<ActiveMissingInfoContent />
				)}
			</>
		</ActiveMissingInfoProvider>
	);
}
