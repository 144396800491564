
import { Fragment } from 'react';

import { StatusRow } from '../../../components/status-row';
import { CompanyInfoForm } from '../../../components/company-info-form';
import { InfoCardManager } from '../../../components/info-card-manager';
import { CompanyInfoHeader } from '../../../components/company-info-header';

import { COMPANY_APP_STATE } from '../../../utils/shared';
	
export function DocsUploadedStateComponent(props) {
	const {
		onInputChange,
		stateList,
		contacts,
		company,
		onSubmit,
		isProcessing
	} = props;

	return (
		<Fragment>
			<CompanyInfoHeader anotherEinLink={ true } hasStatus={ true } />

			<InfoCardManager statuses={ [COMPANY_APP_STATE.DOCS_UPLOADED] } />

			<StatusRow status={ COMPANY_APP_STATE.DOCS_UPLOADED } />
	
			<CompanyInfoForm
				contactList={ contacts }
				data={ company }
				isProcessing={ isProcessing }
				onInputChange={ onInputChange }
				onSubmit={ onSubmit }
				showCompletedFormsSection={ true }
				stateList={ stateList }
			/>
		</Fragment>
	);
}
