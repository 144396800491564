import { Typography, useTheme } from '@bamboohr/fabric';

export interface ProjectNameInfoMessageProps{
    children: string;
}

export function ProjectNameInfoMessage({ children }: ProjectNameInfoMessageProps){
    const { palette } = useTheme();
    return(
        <Typography color={palette.info.main}>
            {children}
        </Typography>
    );
}