import { Fragment } from 'react';

import { CompanyInfoHeader } from '../../../components/company-info-header';
import { CompanyInfoForm } from '../../../components/company-info-form';

export function PreEinStateComponent(props) {
	const {
		onSubmit,
		onInputChange,
		stateList,
		contacts,
		company,
		recordId,
		isProcessing,
	} = props;

	return (
		<Fragment>
			<CompanyInfoHeader />
	
			<CompanyInfoForm
				contactList={ contacts }
				data={ company }
				isProcessing={ isProcessing }
				onInputChange={ onInputChange }
				onSubmit={ onSubmit }
				recordId={ recordId }
				stateList={ stateList }				
			/>
		</Fragment>
	);
}
