import {
	LayoutBox,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import {
	ifFeature,
} from '@utils/feature';
/* @endCleanup encore */
import c from 'classnames';
import { Fragment } from 'react';

import { FedWithholdingContainer } from '../fed-withholding-container';
import { SectionStyledBox } from '../../../section-styled-box';
import { FedWithholding2020 } from 'payroll/withholding/components/fed-withholding-2020.react';

export function OldTraxWithholding(props) {
	const {
		fedPerms,
		federalData2020 = {},
		showFederal2020,
		isNewHirePacket,
		showWithholdingInformation,
		isPayrollAdminUser,
	} = props;

	const oldFedClassNames = ifFeature('encore',
		c(
			'js-pi-withholdings-federal',
			{ 'hidden': showFederal2020 }
		),
		c(
			'js-pi-withholdings-federal fab-FormSection',
			{ 'hidden': showFederal2020 }
		)
	);
	const isNhpPreview = isNewHirePacket && window.previewUser;

	let showFederalWithholding = null;
	if (showWithholdingInformation) {
		({ showFederalWithholding } = showWithholdingInformation);
	}

	return ifFeature('encore',
		(
			<Fragment>
				{ fedPerms !== 0 && (
					<Fragment>
						{ showFederal2020 && (
							<FedWithholding2020
								{ ...federalData2020 }
								showExemptions={ isPayrollAdminUser }
								showWithholding={ showFederalWithholding }
								viewOnly={ (fedPerms === 1) || isNhpPreview }
							/>
						) }
						{ !(isNewHirePacket && showFederal2020) && (
							<fieldset className={ oldFedClassNames }>
								<FedWithholdingContainer showWithholding={ showFederalWithholding } />
							</fieldset>
						) }
					</Fragment>
				) }

				<SectionStyledBox
					paddingBottom={ 4 }
				>
					<fieldset className="js-pi-withholdings-state">
						<h3
							color="primary"
							size="medium"
						>
							{ $.__('State Withholdings') }
						</h3>
						<LayoutBox>
							<div className="semibold pi-withhold__box">
								<div className="js-pi-state-num"></div>
								<div className="js-piStateName" id="js-pi-state-name"></div>
							</div>
							<p className="pi-withhold__written js-piWithholdWritten" id="js-pi-withHold-written"></p>
						</LayoutBox>
					</fieldset>
				</SectionStyledBox>
			</Fragment>
		),
		(
			<Fragment>
				{ fedPerms !== 0 && (
					<Fragment>
						{ showFederal2020 && (
							<FedWithholding2020
								{ ...federalData2020 }
								showExemptions={ isPayrollAdminUser }
								showWithholding={ showFederalWithholding }
								viewOnly={ (fedPerms === 1) || isNhpPreview }
							/>
						) }
						{ !(isNewHirePacket && showFederal2020) && (
							<fieldset className={ oldFedClassNames }>
								<FedWithholdingContainer showWithholding={ showFederalWithholding } />
							</fieldset>
						) }
					</Fragment>
				) }

				<fieldset className="clear clearfix js-pi-withholdings-state fab-FormSection">
					<legend className="baseColor fab-FormSection__legend">
						{ $.__('State Withholdings') }
					</legend>
					<div className="clear clearfix">
						<div className="floatLeft semibold pi-withhold__box">
							<div className="js-pi-state-num"></div>
							<div className="js-piStateName" id="js-pi-state-name"></div>
						</div>
						<p className="pi-withhold__written js-piWithholdWritten" id="js-pi-withHold-written"></p>
					</div>
				</fieldset>
			</Fragment>
		)
	);
}
