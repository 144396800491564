import { BlankState, Button, LayoutBox, Link, makeStyles } from '@bamboohr/fabric';
import { ClipboardPulse60x72 } from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';

interface BlankStateProps {
	reportPreviewUrl: string;
}

export const InProgressBlankState = (props: BlankStateProps): JSX.Element => {
	const { reportPreviewUrl } = props;

	return (
		<LayoutBox marginTop={ifFeature('encore', 15, '126px')}>
			<BlankState
				icon={ifFeature('encore', 'bs-graph', <ClipboardPulse60x72 />)}
				level={ifFeature('encore', 'page')}
				subtitle='You can make changes to Employee Wellbeing after your current survey closes.'
				title='Survey in progress'
				actions={ifFeature('encore', [
					<Button href={reportPreviewUrl} variant='outlined' type='button'>
						{$.__('Go to Employee Wellbeing Report')}
					</Button>,
				])}
			>
				<Button href={reportPreviewUrl} variant='outlined' type='button'>
					{$.__('Go to Employee Wellbeing Report')}
				</Button>
			</BlankState>
		</LayoutBox>
	);
};
