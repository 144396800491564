/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import c from 'classnames';

export function StateWithholdingContainer(props) {
	const {
		showWithholding
	} = props;

	const classNames = ifFeature('encore',
		c('fab-Section fab-boxShadow--large fab-borderRadius--200', 'js-pi-withholdings-state', {
			'StateWithholdingContainer--hidden': showWithholding === false,
		}),
		c('pi-withholdings-state js-pi-withholdings-state', {
			'StateWithholdingContainer--hidden': showWithholding === false,
		})
	);
	return (
		<div className={ classNames }>
			<div id="js-wholdings-state">
				<div className="js-wholdings-loader circleDotLoader pi-state-loader">
					<div className="loaderCircle1 loaderCircle"></div>
					<div className="loaderCircle2 loaderCircle"></div>
					<div className="loaderCircle3 loaderCircle"></div>
					<div className="loaderCircle4 loaderCircle"></div>
					<div className="loaderCircle5 loaderCircle"></div>
					<div className="loaderCircle6 loaderCircle"></div>
					<div className="loaderCircle7 loaderCircle"></div>
					<div className="loaderCircle8 loaderCircle"></div>
					<div className="loaderCircle9 loaderCircle"></div>
				</div>
			</div>
		</div>
	);
}
