import { useState, useEffect } from 'react';
import classNames from 'classnames';
import { isNull } from 'lodash';

import { closestElement } from '@utils/dom';

import { SelectField, LayoutBox, Loader, TextField } from '@bamboohr/fabric';
import { Button, TextButton } from '@fabric/button';

import { PlaidOrManualDropdown } from '../plaid-or-manual-dropdown';
import { CompletedForms } from '../../../company-information.react/components/completed-forms';
import { MoveAwayModal } from '../../../company-information.react/components/move-away-modal';

import {
	validateRoutingNumber,
	MISSING_INFO,
	showPlaidFailedMessage,
} from '../../utils/shared';

import './bank-account-form.styl';

const BANK_FIELDS = {
	ACCOUNT_NICKNAME: 'accountNickname',
	ACCOUNT_NUMBER: 'accountNumber',
	ROUTING_NUMBER: 'routingNumber',
	ACCOUNT_TYPE: 'accountType',
};

const inputProps = { maxLength: 35 };

export function BankAccountForm(props) {
	const {
		customErrorBag,
		isEdit = false,
		plaidUpdateSuccess,
		plaidLinkToken,
	} = props;

	const [routingLogo, setRoutingLogo] = useState(null);
	const [routingName, setRoutingName] = useState(null);
	const [routingError, setRoutingError] = useState(false);
	const [goToPageUrl, setGoToPageUrl] = useState('');
	const [routingLoading, setRoutingLoading] = useState(false);
	const [moveAwayModelOpen, setMoveAwayModalOpen] = useState(false);
	const [errorBag, setErrorBag] = useState({
		accountNickname: false,
		accountNumber: false,
		routingNumber: false,
		accountType: false,
	});
	const [isDisabled, setIsDisabled] = useState(isEdit);
	const [showPlaidDropdown, setShowPlaidDropDown] = useState(false);

	useEffect(() => {
		const { bankAccount, setFooterEnabled } = props;

		if (setFooterEnabled) {
			setFooterEnabled(false);
		}

		if (bankAccount.bankLogo) {
			setRoutingLogo(bankAccount.bankLogo);
		}

		if (bankAccount.bankName) {
			setRoutingName(bankAccount.bankName);
		}

		setMoveAwayListeners();
	}, []);

	useEffect(() => {
		const { showAwayModal } = props;

		if (showAwayModal) {
			_handleCancelClick();
		}
	}, [props.showAwayModal]);

	const setMoveAwayListeners = () => {
		const closestBankInformation = closestElement('.BankInfoContainer');
		document.addEventListener(
			'click',
			(event) => {
				const { target } = event;
				if (target.nodeName === 'A') {
					const isInsideBankInformation = closestBankInformation(target);
					if (!isInsideBankInformation) {
						event.preventDefault();

						let targetedUrl;
						if (event.srcElement.href) {
							targetedUrl = event.srcElement.href;
						} else {
							targetedUrl =
								event.srcElement.parentNode.attributes.href.nodeValue;
						}

						setGoToPageUrl(targetedUrl);

						if (window.hasFormChanges) {
							setMoveAwayModalOpen(true);
						} else {
							window.location = targetedUrl;
						}
					}
				}
			},
			true,
		);
	};

	const handleRoutingNumberValidation = (e) => {
		setRoutingLoading(true);

		validateRoutingNumber(e.target.value)
			.then((res) => {
				if (res.status === 200) {
					setRoutingLoading(false);

					setRoutingLogo(res.data.imageUrl);
					setRoutingName(res.data.name);
				}
			})
			.catch((error) => {
				setRoutingLoading(false);
				setRoutingError(true);
			});
	};

	const handleOnInputChange = (e) => {
		const { company, onInputChange, setFooterEnabled } = props;
		let { id, value } = e.target;
		if (id === 'accountNumber' || id === 'routingNumber') {
			value = value.replace(/\D/g, '');
		}
		// They want the error/logo cleared when input changes
		if (id === BANK_FIELDS.ROUTING_NUMBER) {
			setRoutingError(false);
			setRoutingLogo(null);
			setRoutingName(null);
		}

		window.hasFormChanges = true;
		if (setFooterEnabled) {
			setFooterEnabled(true);
		}

		onInputChange(company.ein, id, value);
	};

	const handleOnAccountTypeSelect = (value) => {
		const { company, onInputChange, setFooterEnabled } = props;

		window.hasFormChanges = true;
		if (setFooterEnabled) {
			setFooterEnabled(true);
		}
		onInputChange(company.ein, BANK_FIELDS.ACCOUNT_TYPE, value);
	};

	const isFormValid = () => {
		const fieldList = [
			BANK_FIELDS.ACCOUNT_NICKNAME,
			BANK_FIELDS.ACCOUNT_NUMBER,
			BANK_FIELDS.ROUTING_NUMBER,
			BANK_FIELDS.ACCOUNT_TYPE,
		];

		let isFormValid = true;
		const newErrorBag = { ...errorBag };

		fieldList.forEach((field) => {
			if (isFieldValid(field)) {
				newErrorBag[field] = false;
			} else {
				newErrorBag[field] = true;
				isFormValid = false;
			}
		});

		setErrorBag(newErrorBag);

		return isFormValid;
	};

	const isFieldValid = (id) => {
		const { bankAccount } = props;

		return !isNull(bankAccount[id]) && bankAccount[id] != '';
	};

	const handleCloseMoveAwayModal = () => {
		setMoveAwayModalOpen(false);
	};

	const handleDontSaveMoveAwayModal = () => {
		window.location = goToPageUrl;
	};

	const _handleCancelClick = () => {
		setMoveAwayModalOpen(true);
	};

	const selectTextOnFocus = (e) => {
		e.target.select();
	};

	const handleSaveMoveAwayModal = () => {
		setMoveAwayModalOpen(false);

		window.hasFormChanges = !isFormValid();

		handleOnSubmit();
	};

	const handleOnSubmit = () => {
		const { company, bankAccount, onSubmit, useCustomErrorBag } = props;

		if (!useCustomErrorBag) {
			if (!isFormValid()) {
				window.setMessage(MISSING_INFO, 'error');
				return;
			}
		}

		onSubmit(company.clientId, bankAccount, isDisabled);
	};

	const renderRoutingNumberStatus = () => {
		if (routingLoading && !routingLogo && !routingName) {
			return (
				<span className="fab-FormNote BankAccountForm__routingLoading">
					<Loader small={true} />
				</span>
			);
		}

		if (routingError && !routingLoading) {
			return (
				<span className="fab-FormNote fab-FormNote--error">
					{$.__("Oops, that's not a valid routing number")}
				</span>
			);
		}

		if (routingLogo && !routingLogo.includes('default-bank')) {
			return (
				<span className="fab-FormNote BankAccountForm__bankLogo">
					<img
						alt="Bank Logo"
						className="BankAccountForm__bankImage"
						src={routingLogo}
					/>
				</span>
			);
		}

		// Fall back to bank name
		return (
			<span className="fab-FormNote BankAccountForm__bankLogo">
				{routingName}
			</span>
		);
	};

	const hasError = (field) => {
		return customErrorBag ? customErrorBag[field] : errorBag[field];
	};

	const getFilesArray = (bankAccount) => {
		return bankAccount.formAchAuthorization === undefined
			? []
			: [bankAccount.formAchAuthorization];
	};

	const {
		bankAccount,
		isProcessing,
		showCompletedFormsSection,
		isVerified,
		hideSubmitButton,
		statusSpacing,
		topSpacing,
	} = props;

	const filesArray = getFilesArray(bankAccount);
	const arrayHasValidFile = filesArray.some((file) => file.fileId !== null);

	const bankFormClasses = classNames(
		'fab-FormSection',
		'BankAccountForm__contentBlock',
		{
			BankAccountForm__verified: isVerified && !statusSpacing,
		},
	);

	const bankAccountFormClasses = classNames('BankAccountForm', {
		'BankAccountForm--topSpacing': topSpacing,
	});

	const clickManualUpdate = () => {
		setIsDisabled(false);
	};

	useEffect(() => {
		setTimeout(
			() => document.getElementById(BANK_FIELDS.ACCOUNT_NUMBER)?.focus(),
			50,
		);
		return () => {
			clearTimeout();
		};
	}, [isDisabled]);

	useEffect(() => {
		setShowPlaidDropDown(!(isVerified || arrayHasValidFile));
	}, [isVerified, arrayHasValidFile]);

	return (
		<div className={bankAccountFormClasses}>
			<div className={bankFormClasses}>
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<span className="fab-FormField">
							<TextField
								label={$.__('Account Nickname')}
								status={hasError(BANK_FIELDS.ACCOUNT_NICKNAME) ? 'error' : ''}
								inputProps={inputProps}
								id={BANK_FIELDS.ACCOUNT_NICKNAME}
								name={BANK_FIELDS.ACCOUNT_NICKNAME}
								onChange={handleOnInputChange}
								note={
									<p>
										{bankAccount.accountNickname?.length || 0}/
										{inputProps.maxLength}
									</p>
								}
								required={true}
								value={bankAccount.accountNickname || ''}
								width={7}
							/>
						</span>
						{showPlaidDropdown && isEdit && (
							<span className="BankAccountForm__plaidDropdown">
								<LayoutBox textAlign="left">
									<PlaidOrManualDropdown
										clickManualUpdate={clickManualUpdate}
										onError={() => showPlaidFailedMessage(isEdit)}
										onSuccess={plaidUpdateSuccess}
										plaidLinkToken={plaidLinkToken}
									/>
								</LayoutBox>
							</span>
						)}
					</div>
				</div>
				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<span className="fab-FormField">
							<TextField
								label={$.__('Account Number')}
								status={hasError(BANK_FIELDS.ACCOUNT_NUMBER) ? 'error' : ''}
								id={BANK_FIELDS.ACCOUNT_NUMBER}
								disabled={isDisabled}
								name={BANK_FIELDS.ACCOUNT_NUMBER}
								onChange={handleOnInputChange}
								onFocus={selectTextOnFocus}
								required={true}
								value={bankAccount.accountNumber || ''}
								width={6}
							/>
						</span>
					</div>
				</div>

				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<span className="fab-FormField">
							<TextField
								label={$.__('Routing Number')}
								status={
									routingError || hasError(BANK_FIELDS.ROUTING_NUMBER)
										? 'error'
										: ''
								}
								id={BANK_FIELDS.ROUTING_NUMBER}
								disabled={isDisabled}
								name={BANK_FIELDS.ROUTING_NUMBER}
								onBlur={handleRoutingNumberValidation}
								onChange={handleOnInputChange}
								required={true}
								value={bankAccount.routingNumber || ''}
								width={6}
								note={renderRoutingNumberStatus()}
							/>
						</span>
					</div>
				</div>

				<div className="fab-FormRow">
					<div className="fab-FormColumn">
						<span className="fab-FormField">
							<SelectField
								label={$.__('Account Type')}
								required={true}
								status={
									hasError(BANK_FIELDS.ACCOUNT_TYPE) ? 'error' : undefined
								}
								id={BANK_FIELDS.ACCOUNT_TYPE}
								isClearable={false}
								disabled={isDisabled}
								items={[
									{ id: 1, text: $.__('Checking'), value: 'Checking' },
									{ id: 2, text: $.__('Savings'), value: 'Savings' },
								]}
								name={BANK_FIELDS.ACCOUNT_TYPE}
								onSelect={handleOnAccountTypeSelect}
								placeholder={$.__('-Select-')}
								value={bankAccount.accountType ? [bankAccount.accountType] : []}
								width={6}
							/>
						</span>
					</div>
				</div>
			</div>

			{showCompletedFormsSection && arrayHasValidFile && (
				<CompletedForms files={filesArray} isBank={true} />
			)}

			<MoveAwayModal
				onClose={handleCloseMoveAwayModal}
				onDontSave={handleDontSaveMoveAwayModal}
				onSave={handleSaveMoveAwayModal}
				visible={moveAwayModelOpen}
			/>

			{!hideSubmitButton && (
				<div className="fab-FormSection">
					<div className="fab-FormRow">
						<div className="fab-FormColumn">
							<span className="fab-FormField">
								<Button
									clickAction={handleOnSubmit}
									isDisabled={!window.hasFormChanges}
									isProcessing={isProcessing}
									outline={!window.hasFormChanges}
									text="Save"
									type="button"
								/>
							</span>
						</div>

						{window.hasFormChanges ? (
							<div className="fab-FormColumn">
								<span className="fab-FormField">
									<TextButton
										clickAction={_handleCancelClick}
										text="Cancel"
										type="button"
									/>
								</span>
							</div>
						) : null}
					</div>
				</div>
			)}
		</div>
	);
}
