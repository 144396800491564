import { useState, useCallback, useEffect, ReactElement } from 'react';
import { RadioGroup, RadioGroupItemProps, TextButton, LayoutBox } from '@bamboohr/fabric';
import { DirectDeposit } from 'payroll/direct-deposit.react';
import { DirectDepositModal } from 'payroll/modals/direct-deposit-modal.react';

import { DirectDepositInfoProps } from '../types';
import { FormSection } from './form-section';

export function DirectDepositInfo(props: DirectDepositInfoProps): ReactElement {
	const [accounts, setAccounts] = useState<Record<string, unknown>[]>([]);
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const [label, setLabel] = useState<string>('');
	const [value, setValue] = useState<string>('');
	const [options, setOptions] = useState<RadioGroupItemProps[]>(null);

	const { employeeId, employeeName, onChange, isPaidByCheck } = props;

	useEffect(() => {
		setLabel($.__('How will %1$s get paid?', employeeName));
	}, [employeeName]);
	useEffect(() => {
		setOptions([
			{
				id: 'payByCheck',
				label: $.__('Pay by Check'),
				value: 'payByCheck',
			},
			{
				id: 'directDeposit',
				label: $.__('Direct Deposit'),
				value: 'directDeposit',
			},
		]);
	}, []);

	const handleRadioChange = useCallback(
		(value: string) => {
			setValue(value === 'payByCheck' ? 'payByCheck' : 'directDeposit');
			onChange('isPaidByCheck', value === 'payByCheck', false);
		},
		[onChange]
	);

	const handleModalOpen = useCallback(() => {
		setModalOpen(true);
	}, []);

	const handleModalClose = useCallback(() => {
		setModalOpen(false);
	}, []);

	const handleSaveSuccess = useCallback(
		(newAccounts) => {
			setAccounts(newAccounts || []);
			handleModalClose();

			if (!newAccounts) {
				handleRadioChange('payByCheck');
			}
		},
		[handleModalClose, handleRadioChange]
	);

	const handleModalSaveSuccess = useCallback(
		(response) => {
			const {
				data: {
					direct_deposits: { BANK },
				},
			} = response;
			handleSaveSuccess(BANK);
		},
		[handleSaveSuccess]
	);

	if (!options) {
		return null;
	}

	return (
		<FormSection title={$.__('Direct Deposit')}>
			<RadioGroup items={options} label={label} onChange={({ value }) => handleRadioChange(value as string)} value={value} />

			{!isPaidByCheck && (
				<LayoutBox paddingLeft={2.5}>
					{accounts.length ? (
						<DirectDeposit
							deposits={accounts}
							employeeId={employeeId}
							hasTrax={true}
							onSaveSuccess={handleSaveSuccess}
							permissionLevel={3}
							useTextButton={true}
						/>
					) : (
						<div>
							<TextButton onClick={handleModalOpen} type='button'>
								+ {$.__('Add Direct Deposit Account(s)')}
							</TextButton>
							<DirectDepositModal
								accounts={accounts}
								employeeId={employeeId}
								hasTrax={true}
								onCancelClick={handleModalClose}
								onSaveSuccess={handleModalSaveSuccess}
								visible={modalOpen}
							/>
						</div>
					)}
				</LayoutBox>
			)}
		</FormSection>
	);
}
