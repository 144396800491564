import {
	BodyText,
	// @startCleanup encore
	Dropdown,
	// @endCleanup encore
	Flex,
	LayoutBox,
	PageHeader,
	Popover,
	TextButton,
	makeStyles,
} from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import React, { useRef, useState } from 'react';
import { redirect } from 'BambooHR.util';
import { isEnabled } from 'FeatureToggle.util';
// @startCleanup encore
import { Billing21x25 } from '@bamboohr/grim';
import { downloadCsvFile } from '../../helpers';
import { api } from '../../services';
import { InvoiceDateRangePicker } from '..';
// @endCleanup encore

const useStyles = makeStyles({
	// @startCleanup encore
	orderedList: {
		listStyle: 'ordered',
	},
	// @endCleanup encore
	unorderedList: {
		listStyleType: 'disc',
	},
});

export function InvoiceHistoryHeader({ selectedInvoices, style, ...props }) {
	const isShowActiveEmployeesEnabled = isEnabled('SHOW_ACTIVE_EMPLOYEES');
	const classes = useStyles();
	const [isBilledForPopoverOpen, setIsBilledForPopoverOpen] = useState(false);
	const ref = useRef(null);
	const hasSelectedInvoices = selectedInvoices.length > 0;

	// @startCleanup encore
	const handleExportSelect = () => {
		const invoiceIds = selectedInvoices.map((inv) => inv.id);

		api.invoices.exportForInvoices
			.get(invoiceIds)
			.then((response) => downloadCsvFile(response, selectedInvoices))
			.catch((error) => {
				window.Rollbar.error('POSH: Unable to retrieve invoice data', {
					error,
					invoiceIds,
				});
				window.setMessage('Oops! Looks like the file export failed. Please try again.', 'error');
			});
	};
	// @endCleanup encore
	return (
		<LayoutBox marginBottom={2}>
			<Flex flexDirection='row' justifyContent='space-between'>
				<PageHeader
					back={$.__('Billing')}
					divider={false}
					// @startCleanup encore
					icon={ifFeature('encore', undefined, <Billing21x25 />)}
					// @endCleanup encore
					onBack={() => redirect('/settings/account/billing')}
					title={$.__('Invoice History')}
				/>
				{isShowActiveEmployeesEnabled && (
					<Flex alignItems='flex-end' paddingBottom={2}>
						<TextButton onClick={() => setIsBilledForPopoverOpen(true)} type='button' ref={ref}>
							{$.__('What am I billed for?')}
						</TextButton>
						<Popover
							anchorEl={ref.current}
							hasMaxWidth={false}
							onClose={() => setIsBilledForPopoverOpen(false)}
							open={isBilledForPopoverOpen}
							title={$.__('What am I billed for?')}
							placement='left'
						>
							<LayoutBox marginRight={ifFeature('encore', 5)} maxWidth='490px'>
								{ifFeature(
									'encore',
									<LayoutBox marginLeft={2.5}>
										<ul className={classes.unorderedList}>
											<li>
												<BodyText>
													{$.__(`Each billing cycle, you are billed for the active employees for each of your products*`)}
												</BodyText>
											</li>
											<li>
												<BodyText>
													{$.__(
														`The active employee count is taken on a different random day each billing cycle sometime during the week before your invoice date.`
													)}
												</BodyText>
											</li>
											<li>
												<BodyText>
													{$.__(
														`When you subscribe to a new product, you are charged for the number of intended employees you provided your sales rep during the sales process until you reach that number. The billing team can adjust that number for you if needed.`
													)}
												</BodyText>
											</li>
										</ul>
									</LayoutBox>,
									<ol className={classes.orderedList}>
										<li>
											<BodyText size='small'>
												{$.__(`Each billing cycle, you are billed for the active employees for each of your products*`)}
											</BodyText>
										</li>
										<li>
											<BodyText size='small'>
												{$.__(
													`The active employee count is taken on a different random day each billing cycle sometime during the week before your invoice date.`
												)}
											</BodyText>
										</li>
										<li>
											<BodyText size='small'>
												{$.__(
													`When you subscribe to a new product, you are charged for the number of intended employees you provided your sales rep during the sales process until you reach that number. The billing team can adjust that number for you if needed.`
												)}
											</BodyText>
										</li>
									</ol>
								)}
								<LayoutBox marginY={1}>
									<BodyText size={ifFeature('encore', 'small', 'extra-small')}>
										{$.__(
											`*Includes contractors, interns, part-time employees, employees with a future hire date, and terminated employees that are still active.`
										)}
									</BodyText>
								</LayoutBox>
								<Message
									link={{
										'data-bi-id': `invoice-history-billing-faq-link`,
										extra: 'externalLink',
										href: 'https://help.bamboohr.com/s/article/991457',
									}}
									text={$.__(`See [Billing FAQ] for more info`)}
								/>
							</LayoutBox>
						</Popover>
					</Flex>
				)}
			</Flex>
			{/* @startCleanup encore */}
			{ifFeature(
				'encore',
				undefined,
				<Flex>
					<InvoiceDateRangePicker {...props} />
					<Dropdown
						biId='settings-account-billing-invoice-history-export'
						ButtonProps={{ outline: true, secondary: true, title: $.__('Export') }}
						isDisabled={!hasSelectedInvoices}
						items={[
							{
								items: [{ key: 'csv', text: $.__('CSV Spreadsheet'), value: 'csv' }],
								key: 'export',
								text: $.__('Export as...'),
								type: 'group',
							},
						]}
						onSelect={handleExportSelect}
					>
						{$.__('Export')}
					</Dropdown>
				</Flex>
			)}
			{/* @endCleanup encore */}
		</LayoutBox>
	);
}
