import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Section } from '@bamboohr/fabric';
import Ctrl, {
	on,
	onClick,
	onDropdownSelect,
} from 'ctrl.deco';
import Comp from 'comp.deco';
import { PoMicroFrontend } from 'micro-frontend.react';
import { unmountComponentAtNode } from 'react-dom';

import {
	htmlentities,
	redirect,
	sanitizeHtml,
	toPascalCase,
} from 'BambooHR.util';

import {
	camelCase,
	each,
	isFunction,
	isUndefined,
	noop,
	kebabCase,
} from 'lodash/index';
import moment from 'moment';
import { updateBannerData } from 'setup-summary-banner.mod/utils';
import AccessLevelSelector from 'access-levels.mod/selector';
import DataTable from 'data-table.comp';
import {
	PayrollAdmin,
} from 'access-levels.mod';
import { showSlidedown } from 'Alerts.mod';

const $rootElem = $('#settings-page');

import subNavTmpl from './templates/sub-nav.micro.html';
import headerTmpl from './templates/header.micro.html';
import mainTmpl from './templates/main.micro.html';
import bodyTmpl from './templates/body.micro.html';
import emptyTmpl from './templates/empty.micro.html';

import './all';
import './admin';
import './payroll-admin';
import './employee';
import './custom';
import './no-access';

import AddEmployeeModal from './add-employee-modal.react';

import './styles.styl';
import {render} from "base/wrapped-render";
import AccessLevelActions from "./access-level-actions/access-level-actions";
import {AccessLevelHeader} from "./access-level-header/access-level-header";
import {AccessLevelSubNav} from "./access-level-sub-nav";

const columnTypeMap = {};
let _mainTmplData = {};
let _tableData = {};
let _data = {};
let $table;

function _setupColumns(accessLevelId, hasPayrollAction) {
	let columns = [];

	if (this.subCtrl.columns) {
		columns = this.subCtrl.columns;
	} else {
		columns.push({
			name: 'employeeName',
			title: $.__('Name'),
			type: 'employeeName',
		})

		if (hasPayrollAction) {
			columns.push({
				name: 'payrollCompanyAccess',
				title: $.__('Payroll Access'),
				type: 'payrollCompanyAccess',
			})
		}

		columns.push({
			name: 'lastLogin',
			title: $.__('Last Login'),
			type: 'date',
		})
	}

	return columns.concat([
		{
			name: 'actions',
			type: 'ba-html',
			orderable: false,
			defaultContent: '',
			data({
				userId,
				employeeId,
			}, type, set, { row }) {
				return {
					html: `
					  <div class="access-button-spacer"
							data-user-id="${ userId }"
							data-employee-id="${ employeeId }"
							data-access-level-id="${accessLevelId}"
						></div>
					`
				};
			}
		}
	]);
}
function _initRow() {
	AccessLevelSelector.refresh();
}

function _setupTable() {
	$table = $('.js-SettingsAccessLevels__table');

	if ($table.length < 1) {
		return;
	}

	// We are using the payrollCompanyAccess property to determine if this access level has payroll actions.
	// If the access level has no payroll actions then the payrollCompanyAccess property won't exist.
	const hasPayrollAction = _tableData.some(row => row.payrollCompanyAccess);

	$table
		.on('DataTable:draw', this::_initRow)
		.data('DataTable:data', _tableData)
		.data('DataTable:columns', this::_setupColumns(_data.id, hasPayrollAction))
		.data('DataTable:config', Object.assign({
			headerCellCallback: (cell, { name }) => {
				cell.setAttribute('data-bi-id', `settings-access-level-${ kebabCase(name) }-table-header`);
			},
			paging: true,
			pageLength: 250,
			info: true,
			ordering: true,
			stripeClasses: [],
			scrollX: false,
			scrollY: false,
		}, this.subCtrl.dataTableConfig || {}));

	Comp($table)(DataTable);
}

@Ctrl('/settings/access_levels/:levelType/:levelId?')
class SettingsAccessLevelsCtrl {
	static context = $rootElem;

	get subNavTmpl() {
		let intervalId;
			intervalId = setInterval(function() {
				const accessID = document.getElementById('access-level-nav')
				if(accessID) {
					render(<AccessLevelSubNav />, accessID)
					stopInterval()
				}
			}, 1000);

		function stopInterval() {
			clearInterval(intervalId);
		}
		return subNavTmpl
	}

	get mainTmpl() {
		return this.subCtrl.mainTmpl || mainTmpl;
	}

	mainTmplData(cb) {
		const {subCtrl} = this;

		if(this._params && this._params.levelType === 'history'){
			return;
		}

		$.getJSON(`/settings/access_levels/group_info/${ (this.levelId || this.levelType) }`)
			.done((data) => {
				_data = data;

				data = Object.assign({
					id: null,
					title: null,
					count: null,
					description: null,
					exportOptions: null,
					tableHeaders: null,
					tableData: [],

					canCombine: true,
					canAdd: true,
					canDuplicate: true,
					canDelete: true
				}, data, subCtrl.tmplData);

				document.title = `${ data.title } (${ data.count })`;

				if (isUndefined(subCtrl.canCombine)) {
					subCtrl.canCombine = data.canCombine;
				}

				if (Array.isArray(data.tableHeaders)) {
					data.tableHeaders = data.tableHeaders.map((header) => {
						columnTypeMap[header.name] = header.type;
						header.label = sanitizeHtml(header.label);
						return header;
					});
				}


				_tableData = data.tableData.map(row => this.formatTableRow(row));

				const headerTmplData = Object.assign({
					showAddBtn: data.canAdd,
					showSettingsBtn: typeof data.showSettingsBtn === 'boolean' ? data.showSettingsBtn : true,
					hasGoogle: !!data.hasGoogle,
					extraButtons: [],
					levelId: this.levelId,
					levelType: this.levelType,

					canAdd: data.canAdd,
					canDuplicate: data.canDuplicate,
					canDelete: data.canDelete,
				}, subCtrl.headerTmplData);

				_mainTmplData = {
					accessLevelId: data.id,
					title: data.title,
					count: data.count,
					description: sanitizeHtml(data.description),
					encoreEnabled: ifFeature('encore', true, false),
					exportOptions: data.exportOptions,
					canAdd: data.canAdd,
					canDuplicate: data.canDuplicate,
					canDelete: data.canDelete,

					headerTmpl: subCtrl.headerTmpl || headerTmpl,
					headerTmplData,

					bodyTmpl: subCtrl.bodyTmpl || bodyTmpl,
					bodyTmplData: Object.assign({
						className: camelCase(this.levelType),
						showTable: (
							Array.isArray(data.tableHeaders) &&
							data.tableHeaders.length > 0
						),

						isEmpty: !(
							Array.isArray(data.tableData) &&
							data.tableData.length > 0
						),
						emptyTmpl: subCtrl.emptyTmpl || emptyTmpl,
						emptyTmplData: Object.assign({
							iconName: 'perm-lock',
							message: $.__('There aren\'t any employees with this Access Level yet.'),
							showAddBtn: this.isPayrollAdmin ? false : headerTmplData.showAddBtn
						}, subCtrl.emptyTmplData)
					}, subCtrl.bodyTmplData),

					footerTmpl: subCtrl.footerTmpl,
					footerTmplData: Object.assign({

					}, subCtrl.footerTmplData),
				};

				cb(_mainTmplData);

				const AccessElement = document.getElementById('js-access-level-actions')
				if( _mainTmplData.exportOptions && AccessElement ) {
					render(<AccessLevelActions exportOptions={_mainTmplData.exportOptions}/>, AccessElement);
				}
				render(<AccessLevelHeader
					employeeModal={() => this.showAddEmployeeModal()}
					headerData={headerTmplData}
					mainTemplateData={_mainTmplData}/>,
					document.getElementById('js-access-level-header'))
			})
			.fail(function() {
				setMessage($.__('There was a problem. Please try again or contact support.'), 'error');
			});
	}

	get levelType() {
		return this._params.levelType;
	}

	get isPayrollAdmin() {
		return this.levelType === 'payroll-admin';
	}

	get levelId() {
		return this._params.levelId;
	}

	get subCtrl() {
		const levelType = toPascalCase(this.levelType);

		return Ctrl.getCtrl(`SettingsAccessLevels${ levelType }Ctrl`) || {};
	}

	@onClick('[data-action="addEmployee"]')
	_onAddEmployeeBtnClick(e, ctrl) {
		ctrl.showAddEmployeeModal();
	}

	@onClick('[data-action="addMultipleEmployees"]')
	_onAddMultipleEmployeesBtnClick(e, ctrl) {
		ctrl.showAddEmployeeModal(true);
	}

	@onClick('[data-action="noAccessOpenManagePayrollAccessModal"]')
	_onNoAccessClick(e, ctrl) {
		const modalProps = {
			employeeId: e.target.dataset.employeeid,
			userId: e.target.dataset.userid,
			accessLevelIds: [_data.id],
			accessLevelName: _data.title,
		}
		ctrl.openManagePayrollCompanyAccess(modalProps)
	}

	@on('AccessLevelSelector:change', '.AccessLevelSelector')
	_onAccessLevelSelectorChange(e, ctrl, levels, user, comp) {
		Ctrl.refresh();
		updateBannerData('enableAccess');
		setMessage($.__('%1$s was moved to %2$s', htmlentities(user.employeeName), htmlentities(comp.label)), 'success');
		listener();
	}

	@on('AccessLevelSelector:openManagePayrollCompanyAccessModal', '.AccessLevelSelector')
	_onManagePayrollCompanyClick(e, ctrl, comp, modalProps) {
		ctrl.openManagePayrollCompanyAccess({...modalProps, accessLevelIds: [_data.id]});
	}

	@onDropdownSelect('.js-SettingsAccessLevels__createDropdown')
	_onCreateDropdownAction({ detail: { value } }) {
		window.location.href = value;
	}

	@onDropdownSelect('.js-SettingsAccessLevels__actionsDropdown')
	_onActionDropdownSelect({ detail: { value } }, ctrl) {
		if (isFunction(ctrl[value])) {
			return ctrl[value]();
		}

		window.location.href = value;
	}

	onMainReady = ifFeature('encore', ($main) => {
		const headerElement = document.getElementById('js-access-levels-header');
		if (headerElement) {
			$(headerElement).css('display', 'block');
			render(<Section.Header divider='bottom' size='large' title={$.__('Access Levels')} />, headerElement);
		}
		
		this::_setupTable();
	}, () => {
		this::_setupTable();
	});

	formatTableHeader(header) {
		const {subCtrl} = this;

		return (subCtrl.formatTableHeader || noop).call(subCtrl, header) || header;
	}

	formatTableRow(row) {
		const {subCtrl} = this;

		row = (subCtrl.formatTableRow || noop).call(subCtrl, row) || row;

		each(row, (val, name) => {
			const type = columnTypeMap[name];

			row[`${ name }__sort`] = this.formatSortValue(name, val, type, row);
			row[name] = this.formatTableCell(name, val, type, row);
		});

		return row;
	}

	formatSortValue(name, val, type, row) {
		const {subCtrl} = this;

		switch (type || name) {
			case 'date':
				val = moment(new Date(val || 0)).toISOString();
				break;
		}

		return (subCtrl.formatSortValue || noop).call(subCtrl, name, val, type, row) || val || '';
	}

	formatTableCell(key, val, type, row) {
		let href;
		let content;

		switch (type) {
			case 'employeeName':
				if (row.employeeId) {
					href = `/employees/employee.php?id=${ row.employeeId }`;
				} else {
					href = `/settings/users/edit/${ row.userId }`;
				}

				val = ifFeature('encore', `
					<a href="${ href }">
						${ htmlentities((row.employeeName || '').trim() || `(${ $.__('no name') })`) }
					</a>
				`, `
					<ba-icon
						class="SettingsAccessLevels__ico ${ row.noAccess ? '' : ' baseFillColor' }"
						name="fab-person-10x12"
						${ row.noAccess ? `` : `brand="true"` }
					></ba-icon>
					<a href="${ href }">
						${ htmlentities((row.employeeName || '').trim() || `(${ $.__('no name') })`) }
					</a>
				`);

				if (row.userNote && row.userNote !== '') {
					val += `
						<span class="SettingsAccessLevels__userNote">(${ row.userNote })</span>
					`;
				}
				break;
			case 'date':
				var mom = moment(val);

				if (mom.isValid()) {
					val = mom.local().calendar();
				} else {
					val = '';
				}
				break;
			case 'payrollCompanyAccess':
				content = row.payrollCompanyAccess?.length ? row.payrollCompanyAccess.join('<br>') : $.__('No Access');
				val = `<a data-action="noAccessOpenManagePayrollAccessModal" data-employeeid="${row.employeeId}" data-userid="${row.userId}">${content}</a>`;
				break;
		}

		const {subCtrl} = this;

		return (subCtrl.formatTableCell || noop).call(subCtrl, key, val, type, row) || val;
	}


	openManagePayrollCompanyAccess(modalProps) {
		// modalProps object
		// {
		// 	employeeId: string;
		// 	userId: string;
		// 	accessLevelIds: string[];
		// 	accessLevelName: string;
		// }
		const managePayrollCompanyAccessModalRoot = document.querySelector('#manage-payroll-company-access-modal-root');

		if (managePayrollCompanyAccessModalRoot) {
			// We are using setTimeout here to include the modal's default closing fade-out when the modal is closed
			const closeModalCallback = () => {
				setTimeout(() => {
					unmountComponentAtNode(managePayrollCompanyAccessModalRoot);
				}, 250);
			}

			const onSaveCallback = () => {
				Ctrl.refresh();
				showSlidedown($.__('Done, your changes have been saved.'), 'success')
			}

			const modalOpenErrorCallback = () => {
				showSlidedown($.__('Oh no. Something went wrong. Manage company payroll access failed to load.'), 'error');
			}

			render(
				<PoMicroFrontend
					{ ...modalProps }
					companyId={ window.SESSION_COMPANY.id }
					closeModalCallback={ closeModalCallback }
					modalOpenErrorCallback={ modalOpenErrorCallback }
					onSaveCallback={ onSaveCallback }
					payrollServicesHost={ window.PAYROLLSERVICES_HOST }
					route="/settings/access_levels/manage-payroll-company-access-modal"
				/>, managePayrollCompanyAccessModalRoot
			);
		}
	}

	showAddEmployeeModal(multiple = false, merge = false) {
		if (this.isPayrollAdmin) {
			multiple = false;

			if (_data.selfAccessLevel=='none') {
				PayrollAdmin.showSettingsModal(true)
					.then(({ employeeAccessLevel }) => {
						_data.defaultESS = employeeAccessLevel;
						_data.selfAccessLevel = 'NotNone';
						this.showAddEmployeeModal();
					});
				return;
			}
		}

		/** used for `__n()` plurals */
		const multi = multiple ? 2 : 1;

		window.BambooHR.Modal.setState({
			isOpen: true,
			isLoading: true,
			biId: 'settings-access-levels-add-employee',
			content: (
				<AddEmployeeModal
					levelId={ this.levelId || this.levelType }
					multiple={ multiple }
					onSubmit={ ({
						employeeIds,
						accessLevel,
						editType,
						workEmail,
						homeEmail,
						employeeList,
					}) => this.saveAddEmployeeModal({
						employeeIds,
						accessLevelIds: [accessLevel.id],
						editType,
						workEmail,
						homeEmail,
						employeeList: employeeList
							.reduce((list, employee) => ({
								...list,
								[employee.id]: employee,
							}), {}),
					}) }
				/>
			),
			headerType: 'text',
			icon: ifFeature('encore',  'user-lock-solid','fab-lock-person-18x18'),
			primaryAction: null,
			primaryActionText: $.__('Done'),
			type: 'medium',
			additionalActionText: this.isPayrollAdmin ? null : $.__n(`Add Many Employees`, `Add a Single Employee`, multi),
			additionalAction: this.isPayrollAdmin ? null : () => this.showAddEmployeeModal(!multiple, true),
		}, merge);

		setTimeout(() => {
			$('#js-multiple-employee-label').attr('data-bi-id', 'add-multiple-employees-modal-available-label');
		}, 500);
	}

	saveNewEmails(data, cb) {
		return $.post(`/employees/updateEmails/${ data.employeeId }`, data, null, 'json')
			.done((result) => {
				if (!result.success) {
					console.error(result.error);
					setMessage(htmlentities(result.errorMessage), 'error');

					window.BambooHR.Modal.setState({
						isProcessing: false,
					}, true);
					return;
				}

				(cb || noop)(result);
			})
			.fail((xhr, status, error) => {
				console.error(error);
				setMessage($.__('There was a problem. Please try again or contact support.'), 'error');

				window.BambooHR.Modal.setState({
					isProcessing: false,
				}, true);
			});
	}

	saveNewAccessLevels(data, cb) {
		return $.post('/settings/access_levels/save_employees_access_level', data, null, 'json')
			.done((result) => {
				if (!result.success) {
					console.error(result.error);
					setMessage(htmlentities(result.errorMessage), 'error');
					window.BambooHR.Modal.setState({
						isProcessing: false,
					}, true);
					return;
				}

				window.BambooHR.Modal.setState({});

				Ctrl.refresh();
				updateBannerData('enableAccess');
				(cb || noop)(result);
			})
			.fail(function(xhr, status, error) {
				console.error(error);
				setMessage($.__('There was a problem. Please try again or contact support.'), 'error');
				window.BambooHR.Modal.setState({
					isProcessing: false,
				}, true);
			});
	}

	saveAddEmployeeModal(data) {
		const {employeeIds, employeeList} = data;

		if (employeeIds.length == 1) {
			const [employeeId] = employeeIds;
			const employee = employeeList[employeeId];

			data.employeeId = employeeId;

			if (!employee.hasEmail) {
				this.saveNewEmails(data, () => {
					this.saveNewAccessLevels(data);
				});
				return;
			}
		}

		this.saveNewAccessLevels(data);
	}
}

/**
 * ba-dropdown is now a themed fabric button.  It was not intended to be rendered
 * as a root react component.  Doing so was causing issues with access-levels and
 * the data-grid. BUGS-13592
 * This is a hack to mitigate this by only allowing a single ba-dropdown instance
 * to render at a time.
 */
function listener() {
	let opened = null;
	let toClose = null;
	let currentRow = null;

	const getSpacer = (elem) => {
		const spacer = $(elem).find('.access-button-spacer')[0];
		return spacer;
	};

	const getSpacerData = (spacer) => {
		if (!spacer) return {};
		const data = $(spacer).data();
		if (!data) return {};

		return data;
	};

	const showButton = (elem) => {
		if (!elem) return;

		const spacer = getSpacer(elem);
		const { userId, employeeId, accessLevelId } = getSpacerData(spacer);
		if (userId && !spacer.innerHTML) {
			spacer.innerHTML = ifFeature('encore', 
				`<button
					ba-access-level-selector
					data-user-id="${ userId }"
					data-employee-id="${ employeeId }"
					data-access-level-id="${ accessLevelId }"
					type="icon"
					size="small"
				></button>`, 
				`<button
					ba-access-level-selector
					data-user-id="${ userId }"
					data-employee-id="${ employeeId }"
					data-access-level-id="${ accessLevelId }"
				></button>`
			);

			AccessLevelSelector.refresh();
		}
	};

	const removeButton = () => {
		if (toClose) {
			// eslint-disable-next-line no-unused-expressions
			toClose.__ba?.disconnectedCallback();
			// eslint-disable-next-line no-unused-expressions
			toClose.parentNode?.removeChild(toClose);
		}
	};
	let dropdownOpen;
	$(document).on('ba:dropdownOpen', 'ba-dropdown[data-user-id]', () => {
		dropdownOpen = null;
		opened = currentRow;
	});

	$(document).on('ba:dropdownClose', 'ba-dropdown[data-user-id]', function(event) {
		dropdownOpen = event.currentTarget;
		toClose = this;
		opened = null;
	});

	$(document).on('mouseenter', '#settings-main-content tbody .fab-Table__row', function (event) {
		currentRow = event.currentTarget;
		if (opened) {
			if(!dropdownOpen) {
				opened = null
				showButton(currentRow);
				return
			}
			return;
		}
		showButton(currentRow);
	});

	$(document).on('mouseleave', '#settings-main-content tbody .fab-Table__row', function (event) {
		removeButton();

		currentRow = null;
		if (!opened) {
			const spacer = getSpacer(event.currentTarget);
			if (spacer) {
				spacer.innerHTML = '';
			}
		}
	});
}
$(window).load(function() {
	listener();
});