export const payerType = Object.freeze({
    Employee: 'Employee',
    Employer: 'Employer',
});

export const taxTypeEngines = Object.freeze({
    OptionalEmployeePaidTax: 'OptionalEmployeePaidTax',
});

export function showPluralVTCCMessage(employeePercent, payer, taxTypeEngine, taxTypeName) {
	// *NOTE: This method returns true if BOTH employee and employer VTCC records exist.
	if (!taxTypeName) {
		return false;
	}

	if (!taxTypeEngine) {
		return false;
	}

	if (taxTypeEngine && taxTypeEngine !== taxTypeEngines.OptionalEmployeePaidTax) {
		return false;
	}

	if (payer === payerType.Employer && employeePercent === 0) {
		return false;
	}

	return true;
}
