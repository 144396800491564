import { Typography, useTheme } from '@bamboohr/fabric';

export interface ProjectNameErrorMessageProps{
    children: string;
}

export function ProjectNameErrorMessage({ children }: ProjectNameErrorMessageProps){
    const { palette } = useTheme();
    return(
        <Typography color={palette.error.main}>
            {children}
        </Typography>
    );
}