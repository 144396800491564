import React, { ReactElement, useState } from 'react';
import { Link, Route, Switch, useLocation } from 'react-router-dom';
import { Box, IconV2Name, makeStyles, Tab, Tabs } from '@bamboohr/fabric';
import {
	Briefcase24x24,
	Calendar15x16,
	CalendarClock16x16,
	ClipboardCheck18x18,
	ClipboardChecklist12x16,
	Document19x22,
	People20x16,
} from '@bamboohr/grim';
import { ifFeature } from '@bamboohr/utils/lib/feature';

import { BenefitsAdminWrapper } from './benefits-admin-wrapper';
import { BenefitsAdminEmployees } from '../benefits-admin-employees';
import { BenefitsCarriers } from '../benefits-carriers';
import { BenefitsFiles } from '../benefits-files';
import { DemoRequest } from '../benefits-admin-upsell';
import { EnrollmentWindows } from '../enrollment-windows';
import { BenefitPlans } from '../plans.react/benefit-plans';
import { SelfEnrollments } from '../self-enrollments';
import { BenefitPlanYears } from '../plan-years/plan-years';

const useStyles = makeStyles(({ palette }) => ({
	content: {
		marginTop: '34px',
	},
	tabsContainer: {
		borderColor: palette.gray[300],
		borderStyle: 'solid',
		borderWidth: '0 0 1px 0',
	},
	tabsRoot: {
		marginLeft: '-8px',
		paddingLeft: 0,
	},
}));

interface BenefitsTabsProps {
	benAdminEmployeeCount: number;
	demoRequest: DemoRequest;
	isCompanyInTrial: boolean;
	showBenefitsAdminDemoRequestContent: boolean;
}

export const BenefitsTabs = (props: BenefitsTabsProps): ReactElement => {
	const { benAdminEmployeeCount, demoRequest, isCompanyInTrial, showBenefitsAdminDemoRequestContent } = props;
	const location = useLocation();
	const styles = useStyles();
	const [demoRequested, setDemoRequested] = useState(demoRequest);
	const countLabel = typeof benAdminEmployeeCount === 'number' ? ` (${benAdminEmployeeCount})`: '';

	const planYearsRoute = '/plan-years';
	interface BenefitTabProps {
		activeIcon: IconV2Name;
		biId: string;
		icon: IconV2Name | ReactElement;
		label: string;
		url: string;
	}

	const tabs: Record<string, BenefitTabProps> = {
		planYears: {
			activeIcon: 'calendar-clock-solid',
			biId: 'plan-years',
			icon: ifFeature('encore', 'calendar-clock-regular', <CalendarClock16x16 />),
			label: $.__('Plan Years'),
			url: planYearsRoute,
		},
		plans: {
			activeIcon: 'clipboard-list-solid',
			biId: 'plans',
			icon: ifFeature('encore', 'clipboard-list-regular', <ClipboardChecklist12x16 />),
			label: $.__('Plans'),
			url: '/',
		},
		enrollment: {
			activeIcon: 'calendar-days-solid',
			biId: 'enrollment',
			icon: ifFeature('encore', 'calendar-days-regular', <Calendar15x16 />),
			label: ifFeature('encore', $.__('Windows'), $.__('Enrollment Windows')),
			url: '/enrollment',
		},
		selfEnrollments: {
			activeIcon: 'calendar-check-solid',
			biId: 'self-enrollments',
			icon: ifFeature('encore', 'calendar-check-regular', <ClipboardCheck18x18 />),
			label: ifFeature('encore', $.__('Elections'), $.__('Employee Elections')),
			url: '/self-enrollments',
		},
		employees: {
			activeIcon: 'users-solid',
			biId: 'employees',
			icon: ifFeature('encore', 'users-regular', <People20x16 />),
			label: $.__(`Employees${countLabel}`),
			url: '/employees',
		},
		carriers: {
			activeIcon: 'briefcase-solid',
			biId: 'carriers',
			icon: ifFeature('encore', 'briefcase-regular', <Briefcase24x24 />),
			label: $.__('Carriers'),
			url: '/carriers'
		},
		files: {
			activeIcon: 'file-solid',
			biId: 'files',
			icon: ifFeature('encore', 'file-regular', <Document19x22 />),
			label: ifFeature('encore', $.__('Carrier PDFs'), $.__('Files')),
			url: '/files',
		},
	};

	return (
		<>
			<Box className={ifFeature('encore', '', styles.tabsContainer)}>
				<Tabs
					classes={ifFeature('encore', undefined, { root: styles.tabsRoot })}
					labelSize='large'
					onChange={() => {
						/**/
					}}
					size='small'
					value={location?.pathname}
					variant='standard'
				>
					{Object.values(tabs).map(({ activeIcon, biId, icon, label, url }) => {
						const tabIcon = ifFeature('encore', location?.pathname === url ? activeIcon : icon, icon);
						return <Tab component={Link} data-bi-id={biId} icon={tabIcon} key={url} label={label} to={url} value={url} />;
					})}
				</Tabs>
			</Box>

			<Box className={styles.content}>
				<Switch>
					<Route exact={true} path={planYearsRoute}>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<BenefitPlanYears />}
						/>
					</Route>
					<Route exact={true} path='/'>
						<BenefitPlans
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							showBenefitsAdminDemoRequestContent={showBenefitsAdminDemoRequestContent}
						/>
					</Route>
					<Route exact={true} path='/enrollment'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<EnrollmentWindows />}
						/>
					</Route>
					<Route exact={true} path='/self-enrollments'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<SelfEnrollments />}
						/>
					</Route>
					<Route exact={true} path='/employees'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<BenefitsAdminEmployees />}
						/>
					</Route>
					<Route exact={true} path='/carriers'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<BenefitsCarriers />}
						/>
					</Route>
					<Route exact={true} path='/files'>
						<BenefitsAdminWrapper
							demoRequest={demoRequested}
							isCompanyInTrial={isCompanyInTrial}
							onDemoRequest={setDemoRequested}
							tab={<BenefitsFiles />}
						/>
					</Route>
				</Switch>
			</Box>
		</>
	);
};
