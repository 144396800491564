import {TextButton} from '@fabric/button';
import { parseISO, format } from '@bamboohr/utils/lib/datetime';
import { 
	/* @startCleanup encore */
	Icon, 
	/* @endCleanup encore */
	IconV2,
	LayoutBox,
    Tooltip
} from '@bamboohr/fabric';
import PaySchedule from 'PaySchedule.mod';
import { ifFeature } from '@bamboohr/utils/lib/feature';

function _showPeopleInSchedule(paySchedule, setModalState) {
    const {listValueId} = paySchedule;
    const title = $.__('%1$s Pay Schedule', paySchedule.name);

    window.Ajax
        .get(`/settings/pay_schedules/employeeInfo/${listValueId}`)
        .then((response) => {
            const {byId, allIds} = response.data;
            setModalState(true, byId, allIds, title);
        })
        .catch(window.errorFallBack);
}

export function _makeColumns(paySchedules, setPeopleModalState, setCalendarModalState) {
    const hasOneInPayroll = paySchedules.some(ps => ps.includeInPayroll === 'yes');

    const columns = [
        {
            header: $.__('Name'),
            cell: r => r.name,
        },
        {
            header: $.__('Frequency'),
            cell: r => r.frequency,
        },
        {
            header: $.__('Period Ends'),
            cell: r => format(parseISO(r.periodEnd), 'MMM dd'),
        },
        {
            header: $.__('People'),
            cell: (r) => {
                return (r.count) === 0 ? r.count : (
                    <TextButton
                        clickAction={ () => _showPeopleInSchedule(r, setPeopleModalState) }
                        inline={ true }
                        size='small'
                        text={ r.count }
                    />
                );
            },
        },
        {
            headerAriaLabel: $.__('Pay Schedule Options'),
            showOnHover: true,
            cell: {
                type: 'actions',
                actions: row => [
                    {
                        icon: ifFeature('encore', 'calendar-regular', 'fab-calendar-money-16x16'),
                        action: () => PaySchedule.previewModal(row, setCalendarModalState),
                        tooltipContent: $.__('Pay Calendar'),
                    },
                    {
                        icon: ifFeature('encore', 'pen-regular', 'fab-pencil-16x16'),
                        href: `/settings/pay_schedules/${ row.id }/edit`,
                        tooltipContent: $.__('Edit'),
                    },
                    {
                        icon: ifFeature('encore', 'trash-can-regular', 'fab-trash-can-14x16'),
                        action: paySchedules.length === 1 ? null : () => PaySchedule.removeModal(row),
                        tooltipContent: paySchedules.length === 1
                            ? $.__('You must have at least one pay schedule or else things get wacky.')
                            : $.__('Delete'),
                    }
                ],
            },
        }
    ];

    if (hasOneInPayroll) {
        columns.unshift({
            headerAriaLabel: $.__('Included in payroll'),
            cell: (r) => {
                if (r.includeInPayroll === 'yes') {
					return ifFeature(
						'encore',
						<LayoutBox marginLeft={2}>
							<Tooltip content={r.einDeleted ? $.__('EIN Deleted') : $.__('Included in Payroll')}>
								<span>
									<IconV2
										color={r.einDeleted ? 'warning-strong' : 'neutral-medium'}
										name={r.einDeleted ? 'triangle-exclamation-regular' : 'circle-dollar-regular'}
										size={20}
									/>
								</span>
							</Tooltip>
						</LayoutBox>,
						<div className={r.einDeleted ? 'PayScheduleList__einDeletedIcon' : 'PayScheduleList__inPayrollIcon'}>
							<Tooltip content={r.einDeleted ? $.__('EIN Deleted') : $.__('Included in Payroll')}>
								<span>
									<Icon name={r.einDeleted ? 'fab-ein-warning-triangle-16x25' : 'fab-circle-money-check-16x18'} />
								</span>
							</Tooltip>
						</div>
					);
                }
                return null;
            },
            cellPaddingReset: 'horizontal',
            headerPaddingReset: 'horizontal',
            width: '32px',
        });
    }
    return columns;
}
