import { Dropdown } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isNil } from 'lodash';

import { taxTypeEngines } from '../modals/domain';
import { GearPopover, LockAction } from './components';
import { isTaxActive } from './utils';

const monthlyPayFrequencyId = 2;

export const getRowAction = (row, handler, isTaxFrequencyLocked, refreshTableData) => {
	if (row.taxTypeName === 'Federal') {
		return renderFederalTaxAction(row, handler, isTaxFrequencyLocked, refreshTableData);
	}

	return renderGearDropdown(row, handler);
};

function renderGearDropdown(row, gearSelectHandler) {
	if (!isTaxActive(row)) {
		return null;
	}

	const buttonProps = {
		size: ifFeature('encore', 'small', 'teenie'),
		width: 1,
		icon: ifFeature('encore', 'ellipsis-vertical-solid', 'fab-gear-15x16'),
		outline: true,
		secondary: true,
	};

	const items = [{ text: $.__('Edit'), value: 'edit' }, { text: $.__('Remove'), value: 'delete' }];

	if (row.latestDatedTax) {
		items.unshift({ text: $.__('End on...'), value: 'end' });
	}

	if (!row.isPfml && row.taxTypeEngine !== taxTypeEngines.OptionalEmployeePaidTax) {
		items.unshift(row.isWorkersComp
			? {
					anchor: 'top',
					type: 'group',
					items: [
						{
							text: $.__('Update Codes & Rates'),
							value: 'updateCodesRates',
						},
					],
			  }
			: { text: $.__('Add New Rate'), value: 'add' });
	}

	return (
		<div className='TaxesTable__gearDropdown'>
			<Dropdown ButtonProps={buttonProps} items={items} onSelect={(value) => gearSelectHandler(value, row)} showCaret={false} type='icon' />
		</div>
	);
}

function renderFederalTaxAction(row, handler, isTaxFrequencyLocked, refreshTableData) {
	const isLocked = isTaxFrequencyLocked(row.group);

	if (isNil(window.SESSION_USER?.supportAdmin)) {
		throw new Error('Session user not initialized when loading taxes table');
	}

	const { supportAdmin } = window.SESSION_USER;

	if (!supportAdmin) {
		return isLocked ? <GearPopover onClick={() => handler('emailSupport', row)} /> : renderGearDropdown(row, handler);
	}

	return row.payFrequencyId !== monthlyPayFrequencyId ? (
		<LockAction
			clientId={row.group}
			isLocked={isLocked}
			openUnlockModal={() => handler('unlockFederalTaxFrequency', row)}
			reloadTable={refreshTableData}
		/>
	) : null;
}
