import { useContext, ReactElement } from 'react';
import { Button, Table, TableColumn, BlankState, Flex, LayoutBox, BodyText } from '@bamboohr/fabric';
import { ActiveMissingInfoContext } from '../context/active-missing-info-provider';
import { ActiveMissingInfoContextData, CompanyData } from '../types';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { CheckCircleOutline120x120 } from '@bamboohr/grim';

export function BackToChecklist(): ReactElement {
	const { companies, isMultiEin } = useContext(ActiveMissingInfoContext) as ActiveMissingInfoContextData;

	const COLUMNS: TableColumn<CompanyData>[] = [
		{
			header: $.__('Company Legal Name'),
			headerAriaLabel: $.__('Company Legal Name'),
			cell: ({ name }: CompanyData): ReactElement => <BodyText>{name}</BodyText>,
			verticalAlign: true,
		},
		{
			align: 'right',
			header: '',
			headerAriaLabel: $.__('Back to Checklist Button'),
			cell: (): ReactElement => (
				<Button href='/app/payroll/registration' size='small' type='button' variant='outlined'>
					{$.__('Back to Checklist')}
				</Button>
			),
		},
	];

	return (
		<Flex flexDirection='column' alignItems='center' gap={3}>
			<BlankState
				title={$.__('Wow! Your Payroll Data is as Clean as a Whistle')}
				subtitle={$.__('Wait, are whistles clean? Anyway, you get the point. Nice work.')}
				icon={ifFeature('encore', 'circle-check-regular', <CheckCircleOutline120x120 />)}
				actions={
					!isMultiEin
						? [
								<Button href='/app/payroll/registration' type='button'>
									{$.__('Back to Payroll Onboarding')}
								</Button>,
						  ]
						: undefined
				}
			/>
			{isMultiEin && (
				<LayoutBox width='735px'>
					<Table caption={$.__('Companies')} columns={COLUMNS} rows={companies} />
				</LayoutBox>
			)}
			{/* @startCleanup encore */}
			{!isMultiEin &&
				ifFeature(
					'encore',
					null,
					<Button href='/app/payroll/registration' type='button'>
						{$.__('Back to Payroll Onboarding')}
					</Button>
				)}
			{/* @endCleanup encore */}
		</Flex>
	);
}
