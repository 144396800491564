import {
	Flex,
	IconButton,
	LayoutBox,
	Popover,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import { ifFeature } from '@utils/feature';
/* @endCleanup encore */
import { Message, ExternalLink } from '@utils/message';
import c from 'classnames';
import {
	/* @startCleanup encore */
	Fragment,
	/* @endCleanup encore */
	useRef,
	useState,
} from 'react';

import {
	SectionStyledBox,
} from '../../../section-styled-box';

export function FedWithholdingContainer(props) {
	const {
		showWithholding
	} = props;

	const legendClassNames = c(
		'baseColor',
		'Fab-Formsection__legend',
		{ 'fab-FormSection--hidden': showWithholding == false }
	);

	const helpPopoverRef = useRef();
	const [showHelpPopover, setShowHelpPopover] = useState(false);

	return ifFeature('encore',
		(
			<SectionStyledBox>
				<LayoutBox
					hidden={ showWithholding === false }
				>
					<h3
						color="primary"
						size="medium"
					>
						<Flex
							alignItems="center"
							flexDirection="row"
						>
							{ $.__('Federal W-4 Withholding') }
							<LayoutBox
								display="flex"
								paddingLeft={ 1 }
								ref={ helpPopoverRef }
							>
								<IconButton
									// className here is necessary as this class is looked for to prevent the sticky footer from showing when it shouldn't
									className="js-no-sticky-footer"
									color="secondary"
									icon="circle-question-regular"
									noBoundingBox={ true }
									onClick={ (event) => {
										event.preventDefault();
										setShowHelpPopover(true);
									} }
									type="button"
								/>
								<Popover
									anchorEl={ helpPopoverRef.current }
									onClose={ () => {
										setShowHelpPopover(false);
									} }
									open={ showHelpPopover }
									placement="bottom"
									title={ $.__('W-4 Withholding') }
								>
									<Message
										link={ ExternalLink('https://apps.irs.gov/app/withholdingcalculator/') }
										text={ $._('This determines how much you will withhold from your paycheck for federal taxes. The IRS has provided a handy [withholding calculator] to help you figure out what to elect.') }
									/>
								</Popover>
							</LayoutBox>
						</Flex>
					</h3>
				</LayoutBox>
				<div id="js-wholdings-federal">
					<div className="js-wholdings-loader circleDotLoader pi-federal-loader">
						<div className="loaderCircle1 loaderCircle"></div>
						<div className="loaderCircle2 loaderCircle"></div>
						<div className="loaderCircle3 loaderCircle"></div>
						<div className="loaderCircle4 loaderCircle"></div>
						<div className="loaderCircle5 loaderCircle"></div>
						<div className="loaderCircle6 loaderCircle"></div>
						<div className="loaderCircle7 loaderCircle"></div>
						<div className="loaderCircle8 loaderCircle"></div>
						<div className="loaderCircle9 loaderCircle"></div>
					</div>
				</div>
			</SectionStyledBox>
		),
		(
			<Fragment>
				<legend className={ legendClassNames }>
					{ $.__('Federal W-4 Withholding') }
					<div
						className="semibold pi-withhold-help"
						data-pop-content="This determines how much you will withhold from your paycheck for federal taxes. The IRS has provided a handy &lt;a href=&quot;https://apps.irs.gov/app/withholdingcalculator/&quot; target=&quot;_blank&quot;rel=&quot;noopener noreferrer&quot;&gt;withholding calculator&lt;/a&gt; to help you figure out what to elect."
						data-pop-content-css-override='{"maxWidth":"230px"}'
						data-pop-placement="bottom"
						data-pop-title="W-4 Withholding"
					>?
					</div>
				</legend>
				<div id="js-wholdings-federal">
					<div className="js-wholdings-loader circleDotLoader pi-federal-loader">
						<div className="loaderCircle1 loaderCircle"></div>
						<div className="loaderCircle2 loaderCircle"></div>
						<div className="loaderCircle3 loaderCircle"></div>
						<div className="loaderCircle4 loaderCircle"></div>
						<div className="loaderCircle5 loaderCircle"></div>
						<div className="loaderCircle6 loaderCircle"></div>
						<div className="loaderCircle7 loaderCircle"></div>
						<div className="loaderCircle8 loaderCircle"></div>
						<div className="loaderCircle9 loaderCircle"></div>
					</div>
				</div>
			</Fragment>
		)
	);
}
