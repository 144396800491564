import { Button, Typography } from '@bamboohr/fabric';
import { showSlidedown } from 'Alerts.mod';
import { redirect } from 'BambooHR.util';
import React, { useState } from 'react';
import Upsell from 'Upsell';
import { payrollItems } from '../../app-configs/upsell';
import api from './api';
import { serializeDemoRequest } from './serializers';

const PayrollUpsell = ({ demoRequest }) => {
	// See example of demo state shape below in defaultProps
	const [demo, setDemo] = useState(serializeDemoRequest(demoRequest));
	const [isRequestPending, setIsRequestPending] = useState(false);

	const handleUpsellRequest = () => {
		setIsRequestPending(true);

		// Make the request
		api.scheduleDemo
			.post()
			.then(({ data }) => {
				// TODO: reimplement when Upsells are moved to Po
				// setDemo(serializeDemoRequest(data));
				// showSlidedown($.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."), 'success');

				//* Temporary solution until Upsells are moved to Po
				redirect(
					window.location.href,
					$.__("Thanks for letting us know! We'll be getting in touch soon to schedule your demo."),
					'success'
				);
			})
			.catch(() => {
				// Show error slidedown
				showSlidedown($.__('Oops... something went wrong. Please try again.'), 'error');
			});

		// Disable processing state
		setIsRequestPending(false);
	};

	return (
		<Upsell>
			<Upsell.Title>{$.__('Discover how BambooHR provides a faster, easier payroll experience')}</Upsell.Title>
			<Upsell.Video title={$.__('Payroll Video')} videoId='wnupecd7vc' />
			<Upsell.Content
				actions={[
					<Button
						data-bi-id='settings-payroll-request-demo-button'
						disabled={demo.isRequested}
						noSpacing={true}
						onClick={handleUpsellRequest}
						processing={isRequestPending}
						size='large'
						type='button'
						width='100'
					>
						{$.__('Schedule a Personalized Demo')}
					</Button>,
				]}
				request={{
					demoRequestedBy: demo.requestedBy,
					demoRequestedDate: demo.requestDate,
					isDemoRequested: demo.isRequested,
				}}
				requestPending={isRequestPending}
			>
				<h4>{$.__('Why switching to payroll from BambooHR makes payday a happy day')}</h4>

				<ul>
					{payrollItems.map((item, index) => (
						<li key={`payroll-item-${index}`}>{item}</li>
					))}
				</ul>
				<Typography color={(theme) => theme.palette.gray[800]} variant='small'>
					{$.__('Please note that payroll is only available for US based employees.')}
				</Typography>
			</Upsell.Content>
		</Upsell>
	);
};

PayrollUpsell.defaultProps = {
	demoRequest: {
		isRequested: false,
		requestedBy: {
			id: null,
			firstName: '',
			lastName: '',
		},
		requestDate: null,
	},
};

export default PayrollUpsell;
