import { 
    BadgeV2, 
    BodyText, 
    Button, 
    Divider, 
    Headline, 
	/* @startCleanup encore */
	Icon, 
	/* @endCleanup encore */
    LayoutBox, 
    Link, 
    StandardModal, 
    Table 
} from "@bamboohr/fabric";
import { ifFeature } from "@bamboohr/utils/lib/feature";

interface Props {
    allIds: Array<string>,
    byId: Object,
    handleClose: () => void,
    isOpen: boolean,
    title: string,
}

interface Row {
    id: string | Number,
    name: string,
    employmentStatus: string,
    payType: string,
}

export function PeopleModal(props: Props) {
    const { allIds, byId, handleClose, isOpen, title, } = props
    const columns = [
        {
            header: $.__('Name'),
            cell: (row: Row) => {
                return (
                    ifFeature('encore',
                        <Link href={ `/employees/employee.php?id=${ row.id }` }>
                            <BodyText>{ row.name }</BodyText>
                        </Link>,
                        <a
                            className="fab-TextButton fab-TextButton--inline fab-TextButton--small fab-link"
                            href={ `/employees/employee.php?id=${ row.id }` }
                        >
                            { row.name }
                        </a>
                    )
                );
            },
            sortBy: (row: Row) => row.name,
        },
        {
            header: $.__('Employment Status'),
            cell: (row: Row) => row.employmentStatus,
        },
        {
            header: $.__('Pay Type'),
            cell: (row: Row) => row.payType,
        }
    ];
    return (
        <StandardModal isOpen={isOpen} onRequestClose={handleClose} >
            <StandardModal.Body
                renderFooter={ (
                    <StandardModal.Footer 
                        actions={ [
                            <Button
                                key="primary"
                                onClick={handleClose}
                                type="button"
                            >
                                {$.__('Done')}
                            </Button>,
                        ] }
                    />
                )}
                renderHeader={ (
                    <StandardModal.Header hasCloseButton={true} title={title} />
                ) }
                size="medium"
            >
                <LayoutBox marginBottom={ifFeature('encore', 3)} marginTop={3} marginX={ifFeature('encore', 3)}>
                    {ifFeature('encore',
                        <BadgeV2 
                            icon="users-regular"
                            title={$.__('Employees in %1$s (%2$s)', title, allIds ? allIds.length : 0)}
                        />,
                        <Headline 
                            color="neutral-strong"
                            icon={<Icon name="fab-employees-24x22" />} 
                            size="small"
                        >
                            {$.__('Employees in %1$s (%2$s)', title, allIds ? allIds.length : 0)}
                        </Headline>
                    )}
                </LayoutBox>
                <LayoutBox marginX={ifFeature('encore', 3)}>
                    <Divider color="neutral-extra-weak" />
                </LayoutBox>
                {ifFeature('encore', 
                    <StandardModal.Constraint>
                        <Table
                            caption={ $.__('Employees in pay schedule') }
                            columns={ columns }
                            initialSort={ {
                                columnIndex: 0,
                                isAsc: true,
                            } }
                            rows={ allIds?.map(id => byId[id]) }
                        />
                    </StandardModal.Constraint>,
                    <Table
                        caption={ $.__('Employees in pay schedule') }
                        columns={ columns }
                        initialSort={ {
                            columnIndex: 0,
                            isAsc: true,
                        } }
                        rows={ allIds?.map(id => byId[id]) }
                    />
                )}
            </StandardModal.Body>
        </StandardModal>
    )
}