/* eslint-disable react/jsx-key */
import { Message } from '@bamboohr/utils/lib/message';

export const benefitsAdmin = {
	disclaimer: $.__('Please note that Benefits Administration is only available for US-based employees.'),
	headline: $.__('Faster, friendlier, more accurate benefits management.'),
	summary: $.__('From onboarding elections to annual open enrollment, Benefits Administration brings the whole benefits experience right into BambooHR.'),
	summaryItems: [
		<Message
			text={$.__(
				'**Provide a simple and familiar experience** for employees that allows them to view the benefits options, enroll online, and access info about their coverage'
			)}
		/>,
		<Message
			text={$.__(
				`**Easily manage, review, and approve** your employees' elections`
			)}
		/>,
		<Message
			text={$.__(
				'**Transfer employee data to your broker or carrier** via a data export, PDF form builder, or automated carrier connections to a growing list of top benefit providers'
			)}
		/>,
		<Message
			text={$.__(
				'**Sync seamlessly with Payroll** for accurate deductions'
			)}
		/>,
	],
	title: $.__('Learn More About Benefits Administration'),
	trialButton: $.__('Enable Benefits Administration'),
	trialDisclaimer: $.__(
		'Benefits Administration is an add-on solution offered for an additional cost. Please contact your Account Executive with any questions.'
	),
	trialHeadline: $.__('Enable Benefits Administration In Your Trial Account'),
	trialSummary: $.__(
		`From onboarding elections to annual open enrollment, BambooHR® Benefits Administration for US-based employees provides a benefits experience that's easy to see and easier for everyone to manage—all the way through the employee life cycle.`
	),
	trialSummary2: $.__(`Some of the benefits you'll experience with Benefits Administration include:`),
	trialSummaryItems: [
		<Message
			text={$.__(
				'**Provide a simple and familiar experience** for employees that allows them to view the benefits options, enroll online, and access info about their coverage'
			)}
		/>,
		<Message
			text={$.__(
				`**Easily manage, review, and approve** your employees' elections`
			)}
		/>,
		<Message
			text={$.__(
				'**Transfer employee data to your broker or carrier** via a data export, PDF form builder, or automated carrier connections to a growing list of top benefit providers'
			)}
		/>,
		<Message
			text={$.__(
				'**Sync seamlessly with Payroll** for accurate deductions'
			)}
		/>,
	],
	trialTitle: $.__('Faster, friendlier, more accurate benefits management.'),
	videoId: '9hmtv81jf9',
};

export const payroll = {
	detail: $.__(
		"Save time, reduce errors, and virtually eliminate double entry by adding payroll directly to your BambooHR account. When you make updates in BambooHR, data automatically flows into payroll to keep everything up-to-date. And with comprehensive payroll reporting, superior customer support, and full-service tax filing included, you'll be surprised how simple payroll really can be."
	),
};

export const payrollItems = [
	$.__('Provides a single source of data in BambooHR, virtually eliminating double-entry'),
	$.__('We give your employees direct access to paystubs in desktop and mobile'),
	$.__('Experience the same customer support you already know and love'),
	$.__('We file federal, state, and local taxes on your behalf to help you stay compliant'),
	$.__('Our comprehensive reporting will help you evaluate payroll impact'),
	$.__('We take data security seriously to give you peace of mind'),
];

export const performance = {
	detail: $.__(
		'Performance reviews are broken. At least, they were. We fixed a few things like huge time commitments, weak results, and infrequency. Then we added meaningful feedback and better ways to measure performance and engagement. The result? Innovative (dare we say, enjoyable?) performance management.'
	),
};

export const performanceItems = [
	<Message text={$.__(`**Create performance assessments** that get to the heart of your employees' unique needs.`)} />,
	<Message text={$.__('**Eliminate paper processes and free up time** with automated reminders.')} />,
	<Message
		text={$.__(
			'**Leverage real-time feedback from peers, managers, or direct reports** to resolve concerns and keep employees engaged.'
		)}
	/>,
	<Message
		text={$.__('**Use targeted performance reporting** to make strategic, data-driven decisions and improve employee growth.')}
	/>,
	<Message
		text={$.__(
			`**Have employees and managers set, track, and align on goals** to drive individual employee development and collectively move your organization's mission forward.`
		)}
	/>,
];

export const timeTracking = {
	detail: $.__(
		'Time Tracking adds hourly time management and reporting to the award-winning BambooHR experience, helping you reduce payroll errors and compliance risks with self-service time entry, approvals, automated reminders, automatic overtime calculations, and accurate payroll reporting.'
	),
};

export const timeTrackingItems = [
	$.__('Give your employees the option to clock in on the web or the mobile app'),
	$.__('Record and track employee time worked, total time spent on projects, or both'),
	$.__('See where your employees clock in and out with Geolocation'),
	$.__('Review and edit timesheets and project hours through four standard reports'),
	$.__(
		'Reduce payroll errors and compliance risks with automated approval reminders, automatic overtime calculations, and accurate payroll reporting'
	),
];
