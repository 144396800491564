import {
	CardSize,
	Icon,
	Interstitial,
} from '@bamboohr/fabric';
import { VerifiedOnlyBody } from './verified-only-body';
import { VerifiedConsentBody } from './verified-consent-body';
import '../interstitial.styl';

const TYPES_MAP = {
	helpdesk: {
		bodyText: $.__('You can now continue to the Bamboo Help Center.'),
	},
	northpass: {
		bodyText: $.__('You can now continue to our BambooHR Learning Courses.'),
		consentDescription: $.__('We use a service called Northpass for our BambooHR Learning Courses. To continue, you\'ll need to agree to our Northpass Terms of Use.'),
	},
	learnupon: {
		bodyText: $.__('You can now continue to our BambooHR Learning Courses.'),
	},
};

const DEFAULT_TYPE = TYPES_MAP.helpdesk;

export function EmailVerified(props) {
	const {
		consentCopy,
		continueLink,
		showConsentPrompt,
		type = 'helpdesk',
	} = props;

	const typeData = TYPES_MAP[type] || DEFAULT_TYPE;
	const headerContent = $.__('Success! Email confirmed.');
	const headerIcon = 'fab-envelope-open-check-72x72';

	return (
		<Interstitial cardSize={ CardSize.SMALL }>
			<div className="EmailVerification">
				<Icon brand={ true } name={ headerIcon } />
				<h4 className="EmailVerification__header">{ headerContent }</h4>
				{ showConsentPrompt ?
					(
						<VerifiedConsentBody
							consentCopy={ consentCopy }
							continueLink={ continueLink }
							description={ typeData.consentDescription }
						/>
					) :
					(
						<VerifiedOnlyBody
							continueLink={ continueLink }
							typeData={ typeData }
						/>
					) }
			</div>
		</Interstitial>
	);
}
