import { useState, useEffect, ReactElement, useContext } from 'react';
import { BodyText, LayoutBox, Loader } from '@bamboohr/fabric';
import Ajax from '@utils/ajax';
import { showSlidedown } from 'Alerts.mod';
import { Withholding } from 'payroll/withholding';
import { ActiveMissingInfoContext } from '../context/active-missing-info-provider';

import { TaxWithholdingInfoProps, ActiveMissingInfoContextData, TaxItems } from '../types';
import { FormSection } from './form-section';
import { SectionDivider } from './section-divider';

export function TaxWithholdingInfo(props: TaxWithholdingInfoProps): ReactElement {
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [taxData, setTaxData] = useState<Record<string, unknown>>(null);
	const [missingTaxData, setMissingTaxData] = useState({});
	const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
	const {
		employeeId,
		stateId,
		stateIdMissing,
		jobLocation: { selected: selectedJobLocation },
		jobLocationMissing,
	} = props;
	const { stillMissingTaxInfo, setStillMissingTaxInfo } = useContext(ActiveMissingInfoContext) as ActiveMissingInfoContextData;

	useEffect(() => {
		setMissingTaxData(taxData);
	}, [taxData]);
	useEffect(() => {
		if (missingTaxData && taxData) {
			const taxInfo = missingTaxData as TaxItems;
			const { federal2020, federal, has2020W4, state, stateUnemployment } = taxInfo;
			const federalFields = federal?.fields;
			const federalFilingStatus2020 = federal2020?.filingStatus;
			const stateSelection = state?.locations?.selected;
			const uiLocation = stateUnemployment?.fields?.locations?.find(
				(options: { selected: boolean | string }) => options?.selected === true
			) as TaxItems;
			const federalFilingStatusSelected = federalFields?.filingStatus?.find(
				(options: { selected: boolean | string }) => options?.selected === true
			) as TaxItems;
			const fedFilingStatus = federalFilingStatusSelected?.val;
			const uiLocationStatus = uiLocation?.val;
			if (has2020W4) {
				setStillMissingTaxInfo({
					...stillMissingTaxInfo,
					federalFilingStatus: federalFilingStatus2020 || false,
					stateTaxLocation: stateSelection,
					unemploymentTaxLocation: uiLocationStatus || false,
				});
			} else {
				setStillMissingTaxInfo({
					...stillMissingTaxInfo,
					federalFilingStatus: fedFilingStatus || false,
					stateTaxLocation: stateSelection || false,
					unemploymentTaxLocation: uiLocationStatus || false,
				});
			}
		}
	}, [missingTaxData]);

	useEffect(() => {
		if (employeeId && stateId && selectedJobLocation) {
			const skipLocationSave = isFirstRender && !stateIdMissing && !jobLocationMissing;
			getEmployeeTaxData(skipLocationSave, employeeId, stateId, selectedJobLocation, setIsLoading, setTaxData);
		}
	}, [employeeId, stateId, selectedJobLocation, stateIdMissing, jobLocationMissing]);
	useEffect(() => {
		setIsFirstRender(false);
	}, []);

	if (isLoading || taxData === null) {
		return (
			<FormSection title={$.__('Tax Withholding')}>
				{isLoading ? (
					<LayoutBox marginY={2}>
						<Loader
							/* @startCleanup encore */
							small={true}
							/* @endCleanup encore */
						/>
					</LayoutBox>
				) : (
					<BodyText color='neutral-medium'>
						{$.__(`You'll need to add a job or home location before adding tax information`)}.
					</BodyText>
				)}
			</FormSection>
		);
	}

	if (taxData) {
		return (
			<Withholding
				data={taxData}
				fedPerms={2}
				isAdminUser={true}
				isFullPage={false}
				isPayrollAdminUser={true}
				newHirePacket={false}
				show={true}
				statePerms={2}
			/>
		);
	}
}

// Utilities
function showGetTaxDataError(): void {
	showSlidedown($.__('Uh oh...there was a problem getting your tax withholding information. Please try again.'), 'error');
}

function getEmployeeTaxDataById(
	employeeId: string,
	setIsLoading: (isLoading: boolean) => void,
	setTaxData: (taxData: Record<string, unknown>) => void
): void {
	Ajax.get(`/payroll_ajax/employee_detailed_tax_information/${employeeId}`)
		.then(({ status, data }) => {
			if (status === 200) {
				setTaxData(data.employeeTaxInformation);
			} else {
				showGetTaxDataError();
			}
			setIsLoading(false);
		})
		.catch(() => {
			showGetTaxDataError();
			setIsLoading(false);
		});
}

function saveLocationsGetTaxData(
	employeeId: string,
	setIsLoading: (isLoading: boolean) => void,
	setTaxData: (taxData: Record<string, unknown>) => void,
	stateId: string,
	selectedJobLocation: string
): void {
	Ajax.put(`/settings/payroll/payroll_checklist/active_employees/update/${employeeId}`, { stateId, jobLocation: selectedJobLocation })
		.then(({ status }) => {
			if (status === 200) {
				getEmployeeTaxDataById(employeeId, setIsLoading, setTaxData);
			} else {
				showGetTaxDataError();
				setIsLoading(false);
			}
		})
		.catch(() => {
			showGetTaxDataError();
			setIsLoading(false);
		});
}

function getEmployeeTaxData(
	skipLocationSave: boolean,
	employeeId: string,
	stateId: string,
	selectedJobLocation: string,
	setIsLoading: (isLoading: boolean) => void,
	setTaxData: (taxData: Record<string, unknown>) => void
): void {
	setIsLoading(true);

	if (skipLocationSave) {
		getEmployeeTaxDataById(employeeId, setIsLoading, setTaxData);
	} else {
		saveLocationsGetTaxData(employeeId, setIsLoading, setTaxData, stateId, selectedJobLocation);
	}
}
