import { Icon } from '@bamboohr/fabric';

import './styles.styl';

const OfferTemplatesBlankState = () => {
	return (
		<div className="OfferTemplates__blankState">
			<div className="OfferTemplates__blankStateIcon">
				<Icon name="fab-pen-signing-120x100" />
			</div>
			<h3 className="OfferTemplates__blankStateTitle">
				{ $.__('Hey, where\'d all the Offer Templates go?') }
			</h3>
			<p className="OfferTemplates__blankStateText">
				{ $.__('Add a Template above.') }
			</p>
		</div>

	);
};

export default OfferTemplatesBlankState;
