import { BodyText, Button, Flex, LayoutBox, StandardModal, StyledBox, Table, TextButton, TextField } from '@bamboohr/fabric';
import React, { Fragment, PureComponent } from 'react';
import { columns } from './columns';
/* @startCleanup encore */
import { Modal as LegacyModal } from 'modal-legacy';
/* @endCleanup encore */
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { Message } from '@bamboohr/utils/lib/message';
import { htmlentities } from 'String.util';

import * as utils from '../../utils';

export default class SourcesTable extends PureComponent {
	deleteSource = (source) => {
		const escapedName = htmlentities(source.name).trim();
		const primaryAction = () => {
			utils.deleteSource(source).then((response) => {
				this.updateRowData(source, true);
			});
		};
		this.openModal(escapedName, primaryAction);
	};

	updateRowData = (data, isRemoved = false) => {
		const { sources, modalProps } = this.state;

		let updatedSource = sources;

		if (isRemoved) {
			updatedSource = sources.filter(function (source) {
				return source.id != data.id;
			});
			modalProps.isOpen = false;
		} else {
			updatedSource.push(data);
		}

		const sorted = updatedSource.sort(function (a, b) {
			return a.listOrder - b.listOrder;
		});
		this.setState({
			sources: sorted,
			sourceValue: '',
			modalProps,
		});
	};

	_handleAddStatus = () => {
		const { sourceValue } = this.state;
		if (sourceValue) {
			this.setState({ isProcessing: true });
			utils.addSource(sourceValue).then((response) => {
				this.setState({ isProcessing: false });
				if (response) {
					this.updateRowData(response);
				}
			});
		} else {
			setMessage('Please enter a Source.', 'error');
		}
	};

	_handleStatusKeyPress = (e) => {
		const { sourceValue } = this.state;
		if (event.key == 'Enter' && sourceValue.length) {
			this._handleAddStatus();
		}
	};

	openModal = (name, primaryAction) => {
		this.setState({
			modalProps: {
				isOpen: true,
				name,
				primaryAction,
			},
		});
	};

	closeModal = () => {
		this.setState({
			modalProps: {
				isOpen: false,
			},
		});
	};

	state = {
		sources: this.props.sources,
		sourceValue: '',
		isProcessing: false,
		modalProps: {
			isOpen: false,
			name: null,
			content: null,
		},
	};

	render() {
		const { sources, sourceValue, modalProps } = this.state;

		const rowData = sources.map((source) => {
			return {
				...source,
				action: () => {
					this.deleteSource(source);
				},
			};
		});

		return (
			<Fragment>
				{ifFeature(
					'encore',
					<LayoutBox marginTop={3}>
						<TextField
							InputProps={{
								endAdornment: (
									<Button
										onClick={(e) => {
											this._handleAddStatus(e);
										}}
										size="medium"
										type="button"
										width={4}
									>
										{$.__('Add')}
									</Button>
								),
							}}
							onChange={(e) => {
								this.setState({ sourceValue: e.target.value });
							}}
							onKeyPress={(event) => {
								this._handleStatusKeyPress(event);
							}}
							placeholder={$.__('Source Name')}
							position="end"
							size="medium"
							value={sourceValue}
							variant="single"
							width={7.75}
						/>
					</LayoutBox>,
					<div className='candidateSources__action'>
						<div className='fab-InputWrapper '>
							<input
								className='fab-TextInput fab-TextInput--width7'
								onChange={(e) => {
									this.setState({ sourceValue: e.target.value });
								}}
								onKeyPress={(event) => {
									this._handleStatusKeyPress(event);
								}}
								placeholder={$.__('Source Name')}
								tabIndex='0'
								type='text'
								value={sourceValue}
							/>
							<button
								className='fab-Button candidateSources__actionInputButton'
								onClick={(e) => {
									this._handleAddStatus(e);
								}}
								type='button'
							>
								{$.__('Add')}
							</button>
						</div>
					</div>
				)}
				{ifFeature(
					'encore',
					<LayoutBox marginTop={3}>
						<Table caption={$.__('Candidate Sources')} columns={columns} rowKey={(row) => row.id} rows={rowData} />
					</LayoutBox>,
					<LayoutBox>
						<Table columns={columns} rowKey={(row) => row.id} rows={rowData} />
					</LayoutBox>
				)}
				{ifFeature(
					'encore',
					<StandardModal isOpen={modalProps.isOpen} onRequestClose={this.closeModal}>
						<StandardModal.Body
							renderFooter={
								<StandardModal.Footer
									actions={[
										<TextButton onClick={this.closeModal} type='button'>
											{$.__('Cancel')}
										</TextButton>,
										<Button key='primary' onClick={modalProps.primaryAction} type='button'>
											{$.__('Delete Source')}
										</Button>,
									]}
								/>
							}
						>
							<StandardModal.Constraint>
								<StandardModal.HeroHeadline
									icon='trash-can-regular'
									iconColor='error-strong'
									text={<Message params={[modalProps.name]} text={$._('Are you sure you want to delete this source?')} />}
								/>
								<LayoutBox marginTop={4}>
									<StyledBox backgroundColor="neutral-extra-extra-weak" borderRadius="small" width="100%">
										<LayoutBox padding="20px">
											<Flex alignItems='center' columnGap={2} flexDirection='column'>
												<BodyText color='neutral-strong' size='small' weight='bold'>
													{modalProps.name}
												</BodyText>
											</Flex>
										</LayoutBox>
									</StyledBox>
								</LayoutBox>
							</StandardModal.Constraint>
						</StandardModal.Body>
					</StandardModal>,
					<LegacyModal
						alternativeActionText={null}
						content={modalProps.content}
						headline={<Message params={[modalProps.name]} text={$._('Are you sure you want to delete the Source \n {1}?')} />}
						icon="fab-trash-can-43x48"
						iconColor="danger"
						isOpen={modalProps.isOpen}
						onClose={() => {
							this.closeModal();
						}}
						primaryAction={modalProps.primaryAction}
						primaryActionText={$.__('Delete Source')}
						secondaryAction={() => {
							this.closeModal();
						}}
						secondaryActionText={$.__('Keep Source')}
						title={$.__('Just Checking...')}
					/>
				)}
			</Fragment>
		);
	}
}
