import React, { createContext, useContext, useState, useEffect } from "react";
import { cancelSurvey, createSurvey, requestDemo } from "../service";
import { Data } from "./data";
import { SurveyStatusType } from "./types";

export const useStatus = (): SurveyStatusType => {
	const {
		update,
		state: {
			nextSurveyScheduledDate,
			displayGetStartedPage,
			displayPromoPage,
			demoPreviouslyRequested,
		}
	} = useContext(Data);

	const showGetStartedPage = displayGetStartedPage === undefined ? false : true;

	const [isSurveyScheduled, setIsSurveyScheduled] = useState(nextSurveyScheduledDate !== '');
	const [isPreviewing, setIsPreviewing] = useState(false);
	const [surveyId, setSurveyId] = useState<number | null>(null);
	const [isDemoRequested, setIsDemoRequested] = useState(demoPreviouslyRequested);

	return {
		showGetStartedPage,
		isSurveyScheduled,
		setIsSurveyScheduled,
		onKillSwitch: () => {
			cancelSurvey().then((data) => {
				update(data);
				setIsSurveyScheduled(false);
			});
		},
		onSurveyScheduleSaveSuccess: (data) => {
			setIsSurveyScheduled(true);
			update(data);
		},
		onGetStartedClick: () => {
			createSurvey().then(data => {
				update(data);
				setIsSurveyScheduled(true);
			});
		},
		onRequestDemo: () => {
			requestDemo().then(() => {
				setIsDemoRequested(true);
			});
		},
		showPreview: (surveyId = null) => {
			setSurveyId(surveyId);
			setIsPreviewing(true);
		},
		hidePreview: () => setIsPreviewing(false),
		surveyId,
		isPreviewing,
		previewEndpoint: showGetStartedPage ? '/settings/employee_wellbeing/demo' : `/settings/employee_wellbeing/preview?surveyId=${surveyId}`,
		displayPromoPage: displayPromoPage === undefined ? false : true,
		demoPreviouslyRequested: isDemoRequested,
	};
};

export const SurveyStatus = createContext<SurveyStatusType>(null);

export const SurveyStatusProvider: React.FC = ({ children }) => {
	const status = useStatus();
	return <SurveyStatus.Provider value={status}>{children}</SurveyStatus.Provider>;
};
