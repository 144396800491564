import { useContext } from 'react';

import { Data, SurveyStatus } from '../utils/';
import { EmployeeWellbeingHeader } from '../shared/employee-wellbeing-header';
import { SchedulingAndFrequency } from './scheduling-and-frequency';
import { Footer } from './footer';
import UpcomingSurveys from './upcoming-surveys';
import useSetup from './utils';
import EditQuestionModal from './edit-questions/modal';
import useEditQuestion from './utils/edit-question';
import { SurveyInProgressView } from '../survey-in-progress-view.react';
import { InProgressBlankState } from '../in-progress-blank-state.react';
import { isEnabled } from 'FeatureToggle.util';

const isToggleEnabled = isEnabled('EMPLOYEE_WELLBEING_SETTINGS_UPDATE_TOGGLE');

export const Setup: React.FC = () => {
	const {
		state: {
			frequencyOptions,
			isInitialSurvey,
			isSurveyInProgress,
			completionRate,
			emailSurveyEndDate,
			emailSurveySendDate,
			employeesComplete,
			employeesTotal,
			reportPreviewUrl,
			timeZone,
		},
	} = useContext(Data);

	const { isSurveyScheduled, showGetStartedPage, displayPromoPage } = useContext(SurveyStatus);

	const {
		setScheduleStartDate,
		isSaveReady,
		setSelectedScheduleValue,
		setSelectedFrequencyValue,
		handleFormSubmit,
		scheduleOptions,
		scheduleStartDate,
		selectedFrequencyValue,
		selectedScheduleValue,
		handleCancelClick,
		upcomingSurveys,
		hasChangesToCancel,
		onKillSwitch,
		scheduleData,
		updateUpcomingSurveys,
		handleIncludeContractorsChange,
		includeContractors,
	} = useSetup();

	const { editQuestion, hideEditQuestion, isOpen, topic, topicId, surveyId } = useEditQuestion();

	if (showGetStartedPage || displayPromoPage) {
		return null;
	}

	return (
		<div>
			<EmployeeWellbeingHeader onKillSwitchClick={onKillSwitch} showKillSwitch={isSurveyScheduled} />
			{isSurveyInProgress ? (
				<>
					{isToggleEnabled ? (
						<SurveyInProgressView
							completionRate={completionRate}
							emailSurveyEndDate={emailSurveyEndDate}
							emailSurveySendDate={emailSurveySendDate}
							employeesComplete={employeesComplete}
							employeesTotal={employeesTotal}
							reportPreviewUrl={reportPreviewUrl}
							timeZone={timeZone}
						/>
					) : (
						<InProgressBlankState reportPreviewUrl={reportPreviewUrl} />
					)}
				</>
			) : (
				<form onSubmit={handleFormSubmit}>
					<SchedulingAndFrequency
						frequencyOptions={frequencyOptions}
						handleIncludeContractorsChange={handleIncludeContractorsChange}
						handleScheduleStartDateChange={setScheduleStartDate}
						handleSelectedFrequencyValueChange={setSelectedFrequencyValue}
						handleSelectedScheduleValueChange={setSelectedScheduleValue}
						includeContractors={includeContractors}
						isInitialSurvey={isInitialSurvey}
						scheduleOptions={scheduleOptions}
						scheduleStartDate={scheduleStartDate}
						selectedFrequencyValue={selectedFrequencyValue}
						selectedScheduleValue={selectedScheduleValue}
					/>
					<EditQuestionModal
						hideEditQuestion={hideEditQuestion}
						isOpen={isOpen}
						scheduleData={scheduleData}
						surveyId={surveyId}
						topic={topic}
						topicId={topicId}
						updateUpcomingSurveys={updateUpcomingSurveys}
					/>
					<UpcomingSurveys editQuestion={editQuestion} upcomingSurveys={upcomingSurveys} />
					<Footer hideCancelButton={!hasChangesToCancel} isSaveButtonDisabled={!isSaveReady} onCancelClick={handleCancelClick} />
				</form>
			)}
		</div>
	);
};
