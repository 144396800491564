import {
	StyledBox,
} from '@bamboohr/fabric';
import type {
	ReactNode,
} from 'react';

interface SectionStyledBoxProps {
	children: ReactNode;
	hidden?: boolean;
	paddingBottom: number | undefined;
	paddingLeft?: number;
}

export function SectionStyledBox(props: SectionStyledBoxProps) {
	const {
		children,
		hidden = false,
		paddingBottom = undefined,
		paddingLeft = 4,
	} = props;
	return (
		<StyledBox
			backgroundColor="neutral-forced-white"
			borderRadius="small"
			borderStyle="solid"
			boxShadow="large"
			hidden={ hidden }
			paddingBottom={ paddingBottom }
			paddingLeft={ paddingLeft }
			v2={ true }
		>
			{ children }
		</StyledBox>
	);
}
