import AppModal from './modal';

import './styles.styl';


export default function ConflictModal({
	conflict = {},
	...props
}) {
	const {
		app = {},
	} = props;

	return (
		<AppModal
			{ ...props }
			alternativeAction={ null }
			headline={ $.__(`Before %1$s can be installed, you'll need to uninstall %2$s`, app.title, conflict.title) }
			icon="fab-triangle-exclamation-48x48"
			primaryActionText={ $.__('Ok, Got it') }
			title={ $.__('Install Conflict') }
			type="conflict"
		/>
	);
}
