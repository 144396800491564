import {
	Flex,
	IconButton,
	LayoutBox,
	Popover,
} from '@bamboohr/fabric';
/* @startCleanup encore */
import {
	ifFeature,
} from '@utils/feature';
/* @endCleanup encore */
import {
	useRef,
	useState,
} from 'react';

import { SectionStyledBox } from '../../../section-styled-box';

export function OtherWithholding() {
	const helpPopoverRef = useRef(null);
	const [showHelpPopover, setShowHelpPopover] = useState(false);

	return ifFeature('encore',
		(
			<SectionStyledBox
				paddingBottom={ 4 }
			>
				<fieldset>
					<h3
						color="primary"
						size="medium"
					>
						<Flex
							alignItems="center"
							flexDirection="row"
						>
							{ $.__('W-4 Withholding') }
							<LayoutBox
								display="flex"
								paddingLeft={ 1 }
								ref={ helpPopoverRef }
							>
								<IconButton
									// className here is necessary as this class is looked for to prevent the sticky footer from showing when it shouldn't
									className="js-no-sticky-footer"
									color="secondary"
									icon="circle-question-regular"
									noBoundingBox={ true }
									onClick={ (event) => {
										event.preventDefault();
										setShowHelpPopover(true);
									} }
									type="button"
								/>
								<Popover
									anchorEl={ helpPopoverRef.current }
									onClose={ () => {
										setShowHelpPopover(false);
									} }
									open={ showHelpPopover }
									placement="bottom"
								>
									{ $.__('This is what you elected to withhold from your paychecks htmlFor tax purposes. Talk to your HR admin if you would like to make a change.') }
								</Popover>
							</LayoutBox>
						</Flex>
					</h3>
					<LayoutBox>
						<div className="floatLeft semibold pi-withhold__box">
							<div className="js-pi-federal-num"></div>
							<div>{ $.__('Federal') }</div>
						</div>
						<div className="floatLeft semibold pi-withhold__box">
							<div className="js-pi-state-num"></div>
							<div className="js-piStateName" id="js-pi-state-name"></div>
						</div>
						<p className="pi-withhold__written js-piWithholdWritten" id="js-pi-withHold-written"></p>
					</LayoutBox>
				</fieldset>
			</SectionStyledBox>
		),
		(
			<fieldset className="clear clearfix">
				<legend className="baseColor">
					{ $.__('W-4 Withholding') }
					<div
						className="semibold pi-withhold-help bhrTooltip"
						data-tip-content={ $.__('This is what you elected to withhold from your paychecks htmlFor tax purposes. Talk to your HR admin if you would like to make a change.') }
						data-tip-delay="100"
						data-tip-text-align="left"
					>
						?
					</div>
				</legend>
				<div className="clear clearfix">
					<div className="floatLeft semibold pi-withhold__box">
						<div className="js-pi-federal-num"></div>
						<div>{ $.__('Federal') }</div>
					</div>
					<div className="floatLeft semibold pi-withhold__box">
						<div className="js-pi-state-num"></div>
						<div className="js-piStateName" id="js-pi-state-name"></div>
					</div>
					<p className="pi-withhold__written js-piWithholdWritten" id="js-pi-withHold-written"></p>
				</div>
			</fieldset>
		)
	);
}
