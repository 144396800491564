export type Action = (
	{
		type: 'updateInstalledApps';
		installedApps: State['installedApps'];
	}
);

export const reducer: React.Reducer<State, Action> = (state: State, action: Action) => {
	const {
		type,
		...payload
	} = action;

	if (type === 'updateInstalledApps') {
		const appList = state.appList
			.map(app => ({
				...app,
				installed: payload && payload.installedApps && payload.installedApps.includes(app.name),
			}));
		const installedApps = appList
			.filter(({ installed }) => installed)
			.map(({ name }) => name);

		return {
			...state,
			appList,
			installedApps,
		};
	}

	return state;
};

export interface AppPublisher {
	logo: string;
	name: string;
	url: string;
}
export interface App {
	conflicts: string[];
	hasConfig: boolean;
	infoUrl: string;
	installUrl: string;
	installed: boolean;
	name: string;
	newPageInstall: boolean;
	publisher: AppPublisher;
	settingsUrl: string;
	title: string;
	dontAllowChanges: boolean;
}
export interface State {
	appList: App[];
	installedApps: string[];
}

export type ModalType = 'install'|'uninstall'|'config';

export const initialState: State = {
	appList: [],
	installedApps: [],
};
