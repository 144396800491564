import { Button, StandardModal, TextButton } from '@bamboohr/fabric';
import { ifFeature } from '@bamboohr/utils/lib/feature';
import { isEnabled } from 'FeatureToggle.util';
import { Modal } from 'modal-legacy';

import { showPluralVTCCMessage } from './domain';

export function DeleteTaxModal(props) {
	const IS_ENABLED = isEnabled('companyTaxPreventRemove');
	const vtccAccessEnabled = isEnabled('VERMONT_CHILD_CARE_TAX_VIEW_ACCESS');

	const {
		clearModalData,
		deleteTax,
		processing,
		targetRowData,
	} = props;

	const {
		payer,
		paymentsPreviouslyMade,
		taxStartDate,
		taxTypeEngine,
		taxTypeName,
	} = targetRowData;

	if (IS_ENABLED && paymentsPreviouslyMade) {
		let headline = $.__(`This tax can't be removed because withholdings for this tax have already begun.`);
		if (taxTypeName && taxStartDate) {
			headline = $.__(`%1$s can't be removed because withholdings for this tax began on %2$s`, taxTypeName, moment(taxStartDate, 'M/D/YYYY').format('MMMM D, YYYY'));
		} else if (!taxTypeName && taxStartDate) {
			headline = $.__(`This tax can't be removed because withholdings for this tax began on %1$s`, moment(taxStartDate, 'M/D/YYYY').format('MMMM D, YYYY'));
		}
		return ifFeature('encore',
			<StandardModal isOpen={ true } isProcessing={ processing } onRequestClose={ clearModalData }>
				<StandardModal.Body
					renderFooter={
						<StandardModal.Footer
							actions={[
								<TextButton
									key='cancelGotItButton'
									onClick={ clearModalData }
									type="button"
								>
									{ $.__('Cancel') }
								</TextButton>,
								<Button key='gotItDeleteButton' onClick={ clearModalData }>
									{ $.__('Got It') }
								</Button>,
							]}
						/>
					}
					renderHeader={<StandardModal.Header title={ $.__('Just So You Know…') } />}
				>
					<StandardModal.Constraint>
						<StandardModal.HeroHeadline icon='circle-exclamation-solid' iconColor='info-medium' subtext={ $.__('To stop these withholdings from continuing, please schedule an end date.') } text={ headline } />
					</StandardModal.Constraint>
				</StandardModal.Body>
			</StandardModal>,
			<Modal
				headline={ headline }
				icon='fab-circle-info-54x54'
				iconColor="info"
				isOpen={ true }
				isProcessing={ processing }
				onClose={ clearModalData }
				primaryAction={ clearModalData }
				primaryActionText={ $.__('Got It') }
				title={ $.__('Just So You Know...') }
			>
				<span className="TaxesTableModal">
					{ $.__('To stop these withholdings from continuing, please schedule an end date.') }
				</span>
			</Modal>
		)
	}


	let headline;
	if (vtccAccessEnabled && showPluralVTCCMessage(targetRowData.employeePercent, payer, taxTypeEngine, taxTypeName)) {
		headline = $.__('Are you sure you want to remove “Vermont Child Care - EE” and “Vermont Child Care - ER”?');
	} else if (taxTypeName) {
		headline = $.__('Are you sure you want to remove “%1$s”?', taxTypeName);
	} else {
		headline = $.__('Are you sure you want to remove this tax?');
	}
	
	let bodyMessage;
	if (paymentsPreviouslyMade) {
		bodyMessage = $.__('It will be removed from your active taxes. However, in order to maintain history, it will be archived in your inactive payroll taxes.');

		if (vtccAccessEnabled && showPluralVTCCMessage(targetRowData.employeePercent, payer, taxTypeEngine, taxTypeName)) {
			bodyMessage = $.__('These taxes will be removed from your active taxes. However, in order to maintain history, they will be archived in your inactive payroll taxes.');
		}
	} else {
		bodyMessage = $.__('No payments for this tax have been made. This will permanently remove it from your company payroll taxes.');
		
		if (vtccAccessEnabled && showPluralVTCCMessage(targetRowData.employeePercent, payer, taxTypeEngine, taxTypeName)) {
			bodyMessage = $.__('No payments for these taxes have been made. This will permanently remove them from your company taxes.');
		}
	}

	return ifFeature('encore',
		<StandardModal isOpen={ true } isProcessing={ processing } onRequestClose={ clearModalData }>
			<StandardModal.Body
				renderFooter={
					<StandardModal.Footer
						actions={[
							<TextButton
								key='cancelDeleteButton'
								onClick={ clearModalData }
								type="button"
							>
								{ $.__('Cancel') }
							</TextButton>,
							<Button key='saveDeleteButton' onClick={ deleteTax }>
								{$.__('Delete')}
							</Button>,
						]}
					/>
				}
				renderHeader={<StandardModal.Header title={ $.__('Just Checking…') } />}
			>
				<StandardModal.Constraint>
					<StandardModal.HeroHeadline icon='trash-can-regular' iconColor='error-strong' subtext={ bodyMessage } text={ headline } />
				</StandardModal.Constraint>
			</StandardModal.Body>
		</StandardModal>,
		<Modal
			headline={ headline }
			icon='fab-trash-can-43x48'
			iconColor="danger"
			isOpen={ true }
			isProcessing={ processing }
			onClose={ clearModalData }
			primaryAction={ deleteTax }
			primaryActionText={ $.__('Delete') }
			title={ $.__('Just Checking…') }
		>
			<span className="TaxesTableModal">
				{ bodyMessage }
			</span>
		</Modal>,
	);
}
