import { ChangeEvent, useState } from 'react';
import moment, { Moment } from 'moment';
import {
	Select,
	Icon,
	TextButton,
	DatePicker,
	Checkbox,
	CheckboxOnChangeParam,
	Headline,
	Label,
	LayoutBox,
	BodyText,
	Flex,
} from '@bamboohr/fabric';

import { SelectItem } from '../../types';
import { useStyles } from './use-styles';
import { BestCadencePopover } from './best-cadence-popover';
import { ifFeature } from '@bamboohr/utils/lib/feature';

type SchedulingAndFrequencyProps = {
	frequencyOptions: SelectItem[];
	handleScheduleStartDateChange: (value: Moment) => void;
	handleSelectedFrequencyValueChange: (value: string) => void;
	handleSelectedScheduleValueChange: (value: string) => void;
	handleIncludeContractorsChange: (param: CheckboxOnChangeParam, event: ChangeEvent<HTMLInputElement>) => void;
	includeContractors: boolean;
	isInitialSurvey: boolean;
	scheduleOptions: SelectItem[];
	scheduleStartDate: Moment;
	selectedFrequencyValue: string;
	selectedScheduleValue: string;
};

const updateSelect = (selectedValues: string[], handler: (string) => void) => {
	handler(selectedValues[0] ?? null);
};

export const SchedulingAndFrequency: React.FC<SchedulingAndFrequencyProps> = ({
	frequencyOptions,
	scheduleOptions,
	scheduleStartDate,
	selectedFrequencyValue,
	selectedScheduleValue,
	handleScheduleStartDateChange,
	handleSelectedFrequencyValueChange,
	handleSelectedScheduleValueChange,
	includeContractors,
	handleIncludeContractorsChange,
	isInitialSurvey,
}) => {
	const [showPopover, setShowPopover] = useState(false);

	const classes = useStyles({ partialPage: scheduleStartDate === undefined });

	const datePickerLabel = isInitialSurvey
		? $.__('Send first survey to all employees on')
		: $.__('Send next survey to all employees on');

	return (
		<div className={classes.root}>
			{ifFeature(
				'encore',
				<Headline size='extra-small' icon='calendar-regular'>
					{$.__('Schedule')}
				</Headline>,
				<div className={classes.sectionHeader}>
					<Icon brand={true} name='fab-calendar-alt-24x24' />
					<h5>{$.__('Schedule')}</h5>
				</div>
			)}

			<div className={classes.fields}>
				{ifFeature(
					'encore',
					<LayoutBox marginBottom={1.5}>
						<Label htmlFor='scheduleStartDate'>{datePickerLabel}</Label>
						<Flex alignItems='center' gap={1.5}>
							<DatePicker
								biId='employee-wellbeing-start'
								minDate={moment().add(1, 'day')?.format('YYYY-MM-DD')}
								onChange={(event) => handleScheduleStartDateChange(moment(event.value))}
								value={scheduleStartDate?.format('YYYY-MM-DD')}
								variant='single'
							/>
							<BodyText size='small'>{$.__('Employees will have one week to complete the survey')}</BodyText>
						</Flex>
					</LayoutBox>,
					<div className={classes.scheduleStart}>
						<label htmlFor='scheduleStartDate'>{datePickerLabel}</label>
						<div>
							<DatePicker
								biId='employee-wellbeing-start'
								minDate={moment().add(1, 'day')?.format('YYYY-MM-DD')}
								onChange={(event) => handleScheduleStartDateChange(moment(event.value))}
								value={scheduleStartDate?.format('YYYY-MM-DD')}
							/>
							<span>{$.__('Employees will have one week to complete the survey')}</span>
						</div>
					</div>
				)}

				<LayoutBox marginBottom={ifFeature('encore', 2, '14px')}>
					<Checkbox
						checked={includeContractors}
						label={$.__('Include Contractors')}
						onChange={handleIncludeContractorsChange}
						value='includeContractors'
					/>
				</LayoutBox>
				{ifFeature(
					'encore',
					<LayoutBox>
						<Label>{$.__('Frequency')}</Label>
						<Flex alignItems='center' gap={1.5}>
							<Select
								items={frequencyOptions}
								onChange={(selectedValues) => updateSelect(selectedValues, handleSelectedFrequencyValueChange)}
								selectedValues={[selectedFrequencyValue]}
								width={7}
							/>
							<Select
								items={scheduleOptions}
								onChange={(selectedValues) => updateSelect(selectedValues, handleSelectedScheduleValueChange)}
								selectedValues={[selectedScheduleValue]}
								width={8}
							/>
							<span id='popoverAnchor'>
								<TextButton onClick={() => setShowPopover(true)} type='button'>
									{$.__("What's the best frequency?")}
								</TextButton>
							</span>
						</Flex>
					</LayoutBox>,
					<div className={classes.scheduleAndFrequency}>
						<p>{$.__('Frequency')}</p>
						<div>
							<Select
								items={frequencyOptions}
								onChange={(selectedValues) => updateSelect(selectedValues, handleSelectedFrequencyValueChange)}
								selectedValues={[selectedFrequencyValue]}
								width={7}
							/>
							<Select
								items={scheduleOptions}
								onChange={(selectedValues) => updateSelect(selectedValues, handleSelectedScheduleValueChange)}
								selectedValues={[selectedScheduleValue]}
								width={8}
							/>
							<span id='popoverAnchor'>
								<TextButton onClick={() => setShowPopover(true)} type='button'>
									{$.__("What's the best frequency?")}
								</TextButton>
							</span>
						</div>
					</div>
				)}
			</div>
			<BestCadencePopover setShowPopover={setShowPopover} showPopover={showPopover} />
		</div>
	);
};
